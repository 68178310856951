.ui-datepicker {
  width: 300px;
  height: 300px;
  margin: 5px auto 0;
  font: 12pt Arial, sans-serif;
}

.ui-datepicker-trigger,
.hasDatepicker {
  cursor: pointer;
}

.ui-datepicker table {
  width: 100%;
}

.ui-datepicker-header {
  background: #fff !important;
  border: none !important;
  color: #ffffff;
  font-family: 'Times New Roman';
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #111;
}


.ui-widget-header .ui-datepicker-prev .ui-icon {
  background: url('../images/icon_prev.svg') no-repeat center/100% auto;

}

.ui-widget-header .ui-datepicker-next .ui-icon {
  background: url('../images/icon_next.svg') no-repeat center/100% auto;
}

.ui-widget-header .ui-corner-all {
  .ui-icon {
    background-size: contain;
  }

  &.ui-state-hover {
    background: none;
    border: none;
    background: #f8f8f8;
    border-radius: 4px;
  }
}


.ui-datepicker {
  .ui-datepicker-title {
    text-align: center;
    font-size: 15px;

    select {
      height: 30px;
      margin: 0 5px;
      border-radius: 4px;
    }
  }
}

.ui-datepicker-prev {
  float: left;
  cursor: pointer;
  background-position: center -30px;
}

.ui-datepicker-next {
  float: right;
  cursor: pointer;
  background-position: center 0px;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
  border: none;
  background: none;
}

.ui-datepicker th {
  text-transform: uppercase;
  font-size: 8pt;
  color: #666666;
}

.ui-datepicker tbody td {
  padding: 0;
}

.ui-datepicker tbody td:last-child {
  border-right: 0px;
}

.ui-datepicker tbody tr:last-child {
  border-bottom: 0px;
}

.ui-datepicker a {
  text-decoration: none;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: inline-block;
  /*font-weight: bold;*/
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
}

.ui-datepicker-calendar .ui-state-default {
  height: 40px;
  width: 40px;
}

.ui-datepicker-calendar .ui-state-hover {
  background: #33adff;
  color: #FFFFFF;
}

.ui-datepicker-calendar .ui-state-active {
  color: #007aff;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: inset 0px 0px 3px 0px rgba(0, 123, 255, .7);

  &:hover {
    color: #fff;
  }
}

.ui-datepicker-unselectable .ui-state-default {
  color: #999;
}