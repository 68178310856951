///********************///
///*     Colors       *///
///********************///

$black: #000;
$white: #fff;
$blue: #182d65;
$lightGray: #dadada;

///********************///
///*    Break Points  *///
///********************///
$screen-xsm: 280px;
$screen-sm: 768px;
$screen-sm-min: 769px;
$screen-md: 1024px;
$screen-md-min: 1025px;
$screen-lg: 1280px;
$screen-xlg: 1920px;

///********************///
///*       Fonts      *///
///********************///

$primary-font: 'Noto Sans KR', Helvetica, 'malgun gothic', 'Apple SD Gothic Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
$mix-font: 'Montserrat', 'Noto Sans KR', Helvetica, 'malgun gothic', 'Apple SD Gothic Neo', 'Microsoft NeoGothic', 'Droid sans', sans-serif;
$montserrat-font: 'Montserrat', sans-serif;


///********************///
///*       level      *///
///********************///
$GNB: 1000;