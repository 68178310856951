@charset "utf-8";

.layer {
  position: fixed;
  left: 0;
  top: 0;

  &_inner {
    position: relative;
    max-height: 80vh;
    background-color: #fff;
    width: 600px;
    transform: translateY(30px);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .5);

    // &::-webkit-scrollbar {
    //     width: 5px;
    //     height: 5px;
    // }
    // &::-webkit-scrollbar-track {
    //     background-color:transparent;
    // }
    // &::-webkit-scrollbar-thumb {
    //     border-radius: 8px;
    //     background-color: rgba(0,0,0,0.1);
    // }
  }

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 30px;
    height: 30px;
    font-size: 0;
    z-index: 9999;
    background: url(../images/layer_close.png) no-repeat center/100% auto;
  }

  &.is-hidden {
    width: 0;
    height: 0;
    overflow: hidden;
  }

  &.is-open {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .layer_inner {
      transform: translateY(0);
      transition: transform .3s ease;
    }
  }

  .top {
    padding: 40px;
    border-bottom: 1px solid $lightGray;
    line-height: 1;

    .title {
      font-size: 34px;
      color: $blue;
      font-weight: 700;
    }
  }

  .layer_btn {
    margin-top: 60px;
    text-align: center;
    margin-bottom: 40px;
    //margin-left: 40px;

    .primary {
      width: 200px;
      height: 60px;
      font-size: 16px;
    }
  }

  .input_inner {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  label {
    font-size: 16px;
    width: 145px;
    font-weight: 500;
  }

  .input_text {
    width: 324px;
    height: 42px;
    border: 1px solid #999;
    padding: 0 19px 3px;

    &::placeholder {
      font-size: 14px;
      color: #999;
    }
  }

  .red_text {
    display: none;
    color: #e92d2d;
    font-size: 14px;
    //display: block;
    margin-left: 20px;
    padding-top: 10px;
    &.is-active{
      display: block;
    }
  }
}
.layer_title{
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 70px 0;
} 
//로그인
.login{
    &_form_inner{
        padding: 80px 65px;
    }
    &_form{
        width: 100%;
    }
    &_find{
        text-align: center;
        //margin-left: 50px;
        a{
            font-size: 16px;
            color: #999;
            margin-right: 20px;
        }
        .line{
            position: relative;
            &::before{
                content: '';
                top: 7px;
                right: -14px;
                position: absolute;
                width: 1px;
                height: 12px;
                background-color: #999;
            }
        }
    }
    &_bottom{
        background-color: #f8f8f8;
        padding: 40px 84px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            font-size: 16px;
            font-weight: 700;
        }
        a{
            width: 120px;
            height: 42px;
            color: #000;
            display: block;
            text-align: center;
            padding-top: 5px;
        }
    }
}
//아이디찾기 / 성공
.find_id{
    &_form_inner{
        padding: 0 60px 60px 60px;
    }
    &_form{
        label{
            width: 115px;
        }
        .id_text{
            border: none;
            width: 190px;
        }
        .input_name{
            width: 364px;
        }
        .input_tel{
            width: 244px;
        }
        .button{
            width: 110px;
            margin-left: 10px;
            height: 42px;
            font-size: 14px;
        }
        .layer_btn{
            margin-top: 78px;
        }
        .continue{
            display: flex;
        }
        .text-box{
            display: flex;
            align-items: center;
        }
        .auth_number{
            display: flex;
            align-items: center;
            border: 1px solid #999;
            width: 244px;
        }
        .time-wrap{
            width: 54px;
            text-align: center;
            color: #e92d2d;
            font-size: 14px;
            &.hide{
                display: none;
            }
        }
    }
}
.find{
    &_info{
        width: 481px;
        background-color: #f8f8f8;
        margin: 0 auto;
        text-align: center;
        padding: 45px 0;
        p{
            font-size: 20px;
            color: #1a2f66;
            font-weight: 600;
        }
        .name{
            margin-bottom: 10px;
        }
    }
}
.layer_id_success{
    .layer_btn{
        margin: 80px 0 90px;
        .button{
            display: inline-block;
            width: 200px;
            height: 60px;
            padding-top: 15px;
            font-size: 16px;
        }
        .secondary{
            color: #000;
            margin-right: 20px;
        }
    }

    .line {
      position: relative;

      &::before {
        content: '';
        top: 7px;
        right: -14px;
        position: absolute;
        width: 1px;
        height: 12px;
        background-color: #999;
      }
    }
  }
//비밀번호찾기
.find_pw {
  &_form_inner {
    padding: 0 60px;

    .layer_btn {
      margin-top: 90px;
      margin-bottom: 100px;
    }
  }

  &_form {
    .input_text {
      width: 364px;
    }

    label {
      width: 115px;
    }
  }
}
//비밀번호재설정
.reset_pw {
  &_form_inner {
    padding: 0 60px;

    .layer_btn {
      margin-top: 90px;
      margin-bottom: 100px;
    }
  }

  &_form {
    .input_inner {
      align-items: flex-start;
    }

    .input_text {
      width: 364px;
    }

    label {
      width: 115px;
      padding-top: 10px;
    }

    .layer_btn {
      margin-top: 30px;
      margin-bottom: 90px;
      margin-left: 0;
    }

    .secondary {
      margin-right: 20px;
    }

    .button {
      width: 200px;
      height: 60px;
      font-size: 16px;
    }
  }
}
//이메일인증
.layer_email {
  .find_text {
    padding: 80px 0 40px;
  }
  .layer_title{
    padding: 80px 0 40px;
  }
  .sub_text {
    text-align: center;
    font-size: 20px;
    padding-bottom: 80px;
  }

  .email {
    &_form_inner {
      padding: 0 60px;
    }

    &_form {
      label {
        width: 125px;
      }

      .input_text {
        width: 330px;
      }

      .layer_btn {
        margin-top: 60px;
        margin-bottom: 80px;
      }
    }
  }
}

//layer.html 버튼css
.layer_button{
    button{
        padding: 10px;
        border: 1px solid #000;
    }
}