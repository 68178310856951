@charset "utf-8";

//공통 인재상
.recruit_inner {
  padding-top: 43px;
}

.recruit_top {
  position: relative;
  margin-bottom: 121px;
  width: 100%;
}

.recruit_textbox {
  width: 688px;
  height: 357px;
  padding-left: 30px;
  background-color: #213e89;
  color: #fff;
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recruit_title {
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 25px;
}

.recruit_sub_title {
  font-size: 30px;
  padding-bottom: 30px;
}

.recruit_dec {
  font-size: 16px;
  font-weight: 100;
}

.img_textbox {
  display: flex;
  justify-content: flex-end;
}

.recruit_bottom {
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
}

//조직문화
.culture_box {
  display: flex;
  margin-bottom: 133px;
}

.culture_list {
  width: 413px;
  border: 1px solid $lightGray;
  border-top: 4px solid #1a2f66;
  margin-right: 80px;
  position: relative;
  padding: 30px 0 30px 30px;

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../images/plus.png) no-repeat center/100% auto;
  }

  &:nth-last-of-type(1) {
    margin-right: 0px;

    &::after {
      display: none;
    }
  }
}

.culture_list_title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 30px;
  color: $blue;
}

.culture_list_text {
  font-size: 16px;
}

.culture_slogan {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 42px;
}

.culture_img_box {
  display: flex;
  justify-content: center;
  margin-bottom: 114px;
}

//복리후생
.welfare_top {
  margin-bottom: 142px;
}

.welfare_inner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 162px;
  width: 100%;
  padding-left: 30px;
}

.welfare_list {
  display: flex;
  flex-direction: column;
  width: calc(33.3% - 53px);
  position: relative;
  margin-right: 79px;
  padding-bottom: 80px;
  margin-bottom: 80px;
  border-bottom: 1px solid $lightGray;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: no-repeat center/100% auto;
  }

  &:nth-of-type(1) {
    &::before {
      width: 58px;
      height: 41px;
      background: url(../images/welfare_list1.png);
    }
  }

  &:nth-of-type(2) {
    &::before {
      width: 38px;
      height: 41px;
      background: url(../images/welfare_list2.png);
    }
  }

  &:nth-of-type(3) {
    &::before {
      width: 58px;
      height: 41px;
      background: url(../images/welfare_list3.png);
    }

    margin-right: 0;
  }

  &:nth-of-type(4) {
    &::before {
      width: 45px;
      height: 32px;
      background: url(../images/welfare_list4.png);
    }
  }

  &:nth-of-type(5) {
    &::before {
      width: 49px;
      height: 40px;
      background: url(../images/welfare_list5.png);
    }
  }

  &:nth-of-type(6) {
    &::before {
      width: 37px;
      height: 42px;
      background: url(../images/welfare_list6.png);
    }

    margin-right: 0;
  }

  &:nth-of-type(7) {
    &::before {
      width: 48px;
      height: 40px;
      background: url(../images/welfare_list7.png);
    }
  }

  &:nth-of-type(8) {
    &::before {
      width: 46px;
      height: 43px;
      background: url(../images/welfare_list8.png);
    }
  }
}

.welfare_title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 29px;
  padding-top: 60px;
}

.welfare_dec {
  font-size: 16px;
}

.second {
  padding-left: 8px;
}

//채용절차

.job_tab_wrap {
  padding-top: 65px;
  padding-bottom: 30px;
  display: flex;
  padding-left: 20px;

  li {
    padding-right: 20px;
    margin-right: 20px;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 14px;
      background-color: #e5e5e5;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 0;
    }

    &:nth-last-of-type(1) {
      &::after {
        display: none;
      }
    }
  }

  a {
    font-size: 20px;
    color: #999;

    .line {
      display: none;
    }
  }

  .is-active {
    a {
      color: $blue;
      position: relative;
      font-weight: bold;
      z-index: 1;

      &::after {
        content: "";
        width: 100%;
        height: 10px;
        position: absolute;
        left: -5px;
        bottom: 0px;
        z-index: -1;
        background-color: #d3d8e7;
        display: block;
        padding: 0 5px;
      }
    }
  }
}

.newcomer {
  position: relative;
}

.newcomer_top {
  margin-bottom: 50px;
}

.newcomer_list {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  padding-left: 136px;

  &:nth-of-type(1) {
    padding-left: 0;
  }
}

.newcomer_bottom {
  padding-bottom: 80px;
}

.bottom_inner {
  padding-bottom: 55px;
}

.bottom_list {
  display: flex;
}

.bottom_title {
  font-size: 20px;
  font-weight: bold;
  padding-left: 25px;
  width: 245px;
  color: #333;
}

.bottom_text {
  font-size: 16px;
  color: #111;
}

.career {
  position: relative;
}

.career_top {
  margin-bottom: 50px;
}

.career_list {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  padding-left: 136px;

  &:nth-of-type(1) {
    padding-left: 0;
  }
}

.career_bottom {
  padding-bottom: 70px;
}

.career_bottom_list {
  font-size: 16px;
  color: #111;
}

//직무소개
.job_section {
  display: none;

  &.on {
    display: block;
  }

  img {
    width: 100%;
  }

  &_top {
    margin-bottom: 76px;

    &.wrap {
      display: flex;
    }
  }

  &_title {
    margin-left: 20px;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 37px;
    margin-top: 30px;
    color: $blue;
  }

  &_bottom {
    padding-bottom: 143px;
    padding-left: 20px;
  }

  .sub_title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    width: 160px;
  }

  .desc {
    font-size: 16px;
    color: #111;
  }

  .bottom {
    margin-right: -40px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
  }

  .item {
    width: 50%;
    padding-right: 40px;
  }

  .bottom1 {
    padding-bottom: 75px;

    .item {
      &_inner {
        display: flex;
      }

      .desc,
      .desc_wrap {
        flex: 1;
      }
    }
  }

  .bottom2 {
    padding-top: 15px;

    .item {
      margin-bottom: 40px;

      &_inner {
        height: 100%;
        border: 1px solid #dadada;
        border-top: 4px solid #1a2f66;
        position: relative;
        padding: 30px;
      }

      // &_title{
      //   font-size: 18px;
      //   color: #333;
      //   font-weight: bold;
      //   padding-bottom: 25px;
      // }
    }

    .desc_wrap {
      padding-top: 25px;
    }

    .sub_title {
      width: 100%;
      font-size: 18px;
      color: #333;
      font-weight: bold;
    }

    // .desc{

    //   padding-top: 25px;
    // }
  }

  .tab_inner {
    img {
      margin-left: -20px;
      width: calc(100% + 20px);
      margin-bottom: 70px;
    }

    .job_section_title {
      font-size: 30px;
      margin-right: 80px;
    }

    &_button {
      display: flex;

      button {
        color: #999999;

        &.is-active {
          color: $blue;
        }
      }
    }

    .job_section_top {
      margin-bottom: 0;
    }

    .job_section_wrap .job_section_bottom {
      display: none;

      &.on {
        display: block;
      }
    }
  }
}