@use "sass:math";
@import 'mixins/clearfix';
@import 'mixins/vw-conversion';
@import 'mixins/font';

@mixin flex-center() {
    display:flex;
    justify-content:center;
    align-items:center;
}

@mixin flex-between() {
    display:flex;
    justify-content:space-between;
    align-items:center;
}

@mixin center($position: absolute) {
    position:$position;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
}

@mixin ellipsis($line: 1) {
    @if ($line == 1) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $line;
    }
}

$browser-context-pc: 14;
$browser-context-tb: 14;
$browser-context-mo: 14;
@mixin em-font-size-pc($pixels, $context: $browser-context-pc){
    font-size:(math.div($pixels,$context)) * 1rem;
}
@mixin em-font-size-tb($pixels, $context: $browser-context-tb){
    font-size:(math.div($pixels,$context)) * 1rem;
}
@mixin em-font-size-mo($pixels, $context: $browser-context-mo){
    font-size:(math.div($pixels,$context)) * 1rem;
}