.my_page {
  border-top: 2px solid $blue;
  padding-top: 75px;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    padding-bottom: 35px;
  }

  .desc {
    font-size: 16px;
    color: #333;
    padding-bottom: 30px;
  }

  form {
    input {
      width: 300px;
      height: 42px;
      padding: 12px 20px;
      border: 1px solid #dadada;
      font-size: 14px;

      &::placeholder {
        color: #999;
      }
    }

    &.form_password {
      padding-bottom: 130px;
    }
  }

  .button_wrap {
    padding-top: 40px;
  }

  &.my_password {
    text-align: center;
  }

  //회원탈퇴
  &.cancel_membership {
    text-align: left;
    padding-bottom: 90px;

    .title,
    .desc {
      text-align: center;
    }

    .notice {
      width: 645px;
      margin: 0 auto;
      text-align: left;
      color: #999;
      font-size: 14px;
      padding-left: 35px;
    }

    .button_wrap {
      padding-top: 57px;
    }
  }

  //개인정보 수정
  &.edit_info {
    margin: 0 auto;
    width: 926px;

    .title {
      text-align: center;
    }

    form {
      color: #333;
      font-size: 14px;

      ul {
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
      }

      li {
        display: flex;

        &.fixed_value {
          display: flex;
          align-items: center;
        }
      }

      input {
        width: 504px;
      }

      label {
        background: #f8f8f8;
        width: 170px;
        font-size: 16px;
        padding: 6px 25px 15px;
        font-weight: 500;
      }

      .fixed_value {
        &:first-child {

          p,
          label {
            padding-top: 30px;
          }
        }

        p {
          padding: 4px 36px 28px;
        }

        label {
          padding: 4px 25px 28px;
        }
      }

      .input_box {
        position: relative;
        padding: 0px 36px 45px;
      }

      .red_text {
        display: none;
        position: absolute;
        color: #e92d2d;
        padding: 3px 0 0 20px;

        &.is-active {
          display: block;
        }
      }
    }

    .cancle_link {
      padding-top: 20px;

      a {
        color: #333;
        font-weight: bold;
      }
    }

    .button_wrap {
      padding: 42px 0 143px;
    }
  }
}