.recruit_guide {
  padding-top: 80px;

  .inquiry_form {
    padding-top: 20px;

    .category{
      .item{
        width: 100%;
      }
      .title {
        padding-left: 33px;
      }
      .details{
        width: calc(100% - 165px);
      }
    }

    .item.radio_box {
      padding-right: 18px;
    }
  }

  .input-radio {
    label:last-child {
      margin-right: 0;
    }
  }

  .form_agree {
    color: #333;

    .input-checkbox {
      display: flex;
      justify-content: space-between;
    }

    .agree_title {
      color: #111;
    }

    .agree_desc {
      .item {
        padding-bottom: 5px;
      }

      .text_indent {
        text-indent: 10px;
      }

    }

    .agree_check {
      padding-top: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .desc {
        font-size: 16px;
      }
    }

    .link_term_btn {
      font-size: 14px;
      padding-right: 30px;
      position: relative;
      color: #333;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: url('../images/link_move_arrow.png') no-repeat center/100% auto;
      }
    }
  }

  .button_wrap {
    padding-top: 0;
    padding-bottom: 10px;
  }

  //문의내역 확인
  .inquiry_detail {
    padding-bottom: 0;
    margin-bottom: 128px;
    position: relative;

    .table_category {
      padding-bottom: 50px;

      .textarea {
        label {
          align-items: flex-start;
          padding: 24px 33px;
        }

        .details {
          padding: 24px 0;
          //overflow-y: auto;
        }

        .item {
          align-items: flex-start;
        }
      }

      .item {
        .user_name {
          width: 300px;
        }

        &.detail_contents {
          .details {
            min-height: 200px;

          }
        }

        &.detail_answer {
          border-top: 1px solid #dadada;

          .details {
            min-height: 200px;
          }
        }
      }
    }

    .button_wrap {
      padding-bottom: 0;
    }

    .page_wrap {
      position: absolute;
      bottom: 25px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .page {
        font-size: 14px;
        color: #999;
        display: flex;
        align-items: center;

        &_title {
          max-width: 245px;
          @include ellipsis();
        }

        .arrow {
          position: relative;

          &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background: url('../images/icon_prev.svg') no-repeat center/100% auto;
            width: 9px;
            height: 15px;
            opacity: 0.5;
          }
        }

        &.prev {
          padding-left: 20px;

          .arrow {
            padding-left: 30px;

            &:after {
              left: 0;
            }
          }

          .page_title {
            padding-left: 23px;
          }
        }

        &.next {
          padding-right: 20px;

          .arrow {
            padding-right: 30px;

            &:after {
              right: 0;
              transform: translateY(-50%) rotate(-180deg);
            }
          }

          .page_title {
            padding-right: 23px;
          }
        }

        &:hover {
          color: #333;

          .arrow:after {
            opacity: 1;
          }
        }
      }
    }
  }
}