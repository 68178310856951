@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  word-wrap: break-word;
}

a {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  font-size: 18px;
}

body {
  letter-spacing: -0.01em;
  line-height: 1.6;
  color: #000;
  font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-font-smooting: antialiased;
  -moz-font-smoothing: grayscale;
  text-size-adjust: none;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border: 0 none;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
textarea {
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0;
  font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif;
  font-size: 1rem;
}

input {
  margin: 0;
}

img,
fieldset {
  border: 0;
}

ul,
ol,
li {
  list-style-type: none;
}

li img {
  vertical-align: top;
}

button {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-family: "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif;
  font-size: 1rem;
}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
  font-size: 0;
}

.blind {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
}

body {
  min-width: 1280px;
}
body.layer-opens {
  overflow: hidden;
}
body.nav-opens {
  overflow: hidden;
}
#wrapper {
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.main__inner {
  max-width: 1480px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
}

.top {
  padding-bottom: 70px;
}
.top .main_title {
  font-size: 34px;
  font-weight: 700;
  color: #182d65;
}
.top .main_desc {
  font-size: 50px;
  color: #333;
}

#container {
  width: 100%;
  min-height: 600px;
}

.button.secondary {
  color: #000;
  border: 1px solid #000;
  background-color: #fff;
}
.button.primary {
  color: #fff;
  background-color: #1a2f66;
  border: 1px solid #1a2f66;
}
.button.cancel {
  color: #fff;
  background-color: #999;
}
.button.calendar_btn {
  background: url("../images/icon_calender.png") no-repeat center/100% auto;
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

.button_wrap {
  display: flex;
  justify-content: center;
}
.button_wrap .button {
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.button_wrap .button + .button {
  margin-left: 20px;
}

.breadcrumb {
  padding-top: 55px;
  padding-bottom: 65px;
  font-family: "Montserrat", "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif;
  display: flex;
  align-items: center;
}
.breadcrumb a,
.breadcrumb span {
  font-size: 16px;
  color: #000;
}
.breadcrumb .gt {
  position: relative;
  display: block;
  width: 7px;
  height: 12px;
  background: url(../images/breadarow.png) no-repeat center/100% auto;
}
.breadcrumb .home {
  margin-right: 15px;
  font-weight: 500;
}
.breadcrumb .route {
  margin: 0 18px;
}
.breadcrumb .current {
  margin-left: 18px;
}

.input-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.input-checkbox {
  position: relative;
}
.input-checkbox input {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  left: -999px;
  top: 0;
}
.input-checkbox label {
  position: relative;
  padding-left: 35px;
}
.input-checkbox label:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/checkbox_blank.png");
}
.input-checkbox input:checked + label:before {
  background-image: url("../images/checkbox_checked.png");
}
.input-radio {
  position: relative;
}
.input-radio input {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  left: -999px;
  top: 0;
}
.input-radio label {
  color: #444 !important;
  position: relative;
  padding-left: 32px;
  margin-right: 35px;
}
.input-radio label:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../images/radiobox_blank.png");
}
.input-radio input:checked + label:before {
  background-image: url("../images/radiobox_checked.png");
}

.main__inner {
  margin: 0 auto;
  padding-top: 100px;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #182d65;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
header.main_header {
  background-color: transparent;
}
header.main_header.scroll {
  background-color: #fff;
}
header.main_header.scroll .logos a {
  background: url("../images/logo_blue.png") no-repeat center/100% auto;
}
header.main_header.scroll .login_btn {
  color: #000;
}
header.main_header.scroll .depth1 .title {
  color: #000;
}
header.main_header.scroll .right .user_name {
  color: #000;
}
header::after {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  top: 100px;
  background-color: #e5e5e5;
}
header .gnb {
  width: 100%;
  max-width: 1600px;
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  position: relative;
}
header .gnb .logos {
  margin-top: 18px;
  width: 160px;
  height: 66px;
  flex-shrink: 0;
}
header .gnb .logos a {
  background: url("../images/logo.png") no-repeat center/100% auto;
  display: block;
  height: 100%;
  padding: 20px 0;
}
header .gnb nav {
  display: flex;
  flex: 1;
  padding-left: 160px;
}
header .gnb .login_btn {
  padding: 7px 27px;
  font-size: 16px;
  border: 1px solid #999;
  color: #fff;
  font-weight: bold;
  margin-left: 45px;
}
@media (max-width: 1640px) {
  header .gnb .login_btn {
    right: 20px;
  }
  header .gnb nav {
    padding-left: 80px;
    padding-right: 50px;
  }
}
header .depth1 {
  display: flex;
}
header .depth1_item:nth-child(3) .depth2 {
  margin-right: 0;
}
@media (max-width: 1439px) {
  header .depth1_item {
    width: 185px;
  }
}
header .depth1 .title {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  position: relative;
  line-height: 99px;
  margin: 0px 75px;
}
header .depth1 .title.on::after {
  position: absolute;
  bottom: -1px;
  left: 0px;
  width: 100%;
  height: 4px;
  background: #fff;
  content: "";
}
header .depth2 {
  display: none;
  padding: 30px 0 45px;
  margin: 0px 60px;
  white-space: nowrap;
}
header .depth2_item {
  padding: 15px 17px;
}
header .depth2_item a {
  color: #6f6f6f;
  position: relative;
}
header .depth2_item a:after {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  margin-top: 5px;
  top: 100%;
  left: 0%;
  background-color: #182d65;
  transition: width 0.3s;
}
header .depth2_item a:hover {
  color: #182d65;
}
header .depth2_item a:hover:after {
  width: 100%;
}
header .depth2_item.is-active a {
  color: #182d65;
}
header .depth2_item.is-active a:after {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  margin-top: 5px;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #182d65;
}
header .right {
  margin-top: 30px;
  color: #fff;
  display: flex;
  align-items: center;
}
header .right .user_name {
  font-size: 16px;
  color: #fff;
  position: relative;
}
header .right .user_name:after {
  content: "|";
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 20px;
}
header:hover {
  background-color: #fff;
  border-bottom: none;
}
header:hover .logos a {
  background: url("../images/logo_blue.png") no-repeat center/100% auto;
}
header:hover .login_btn {
  color: #000;
}
header:hover .depth1 .title {
  color: #000;
}
header:hover .depth1 .title.on:after {
  background: #182d65;
}
header:hover .depth2 {
  display: block;
}
header:hover::after {
  display: block;
}
header:hover .right {
  color: #000;
}
header:hover .right .user_name {
  color: #000;
}

footer {
  width: 100%;
  background-color: #182d65;
}
footer .main__inner {
  padding-top: 45px;
}
footer .footer_top {
  padding-bottom: 80px;
  margin-bottom: 40px;
  border-bottom: 1px solid #0f1c3f;
}
footer .footer_list_inner {
  display: flex;
}
footer .footer_list {
  width: 216px;
  margin-right: 178px;
}
footer .footer_list:nth-of-type(4) {
  margin-right: 0;
}
footer .footer_list .list_item {
  padding-bottom: 20px;
  border-bottom: 1px solid #8596c2;
  font-size: 24px;
  font-weight: bold;
  padding-left: 10px;
}
footer .footer_list .list_item a {
  color: #fff;
}
footer .list2 {
  padding-top: 20px;
}
footer .list2_item {
  padding-bottom: 12px;
  padding-left: 10px;
}
footer .list2_item:nth-last-of-type(1) {
  padding-bottom: 0;
}
footer .list2_item a {
  font-weight: 100;
  font-size: 18px;
  color: #8596c2;
}
footer .footer_bottom {
  padding-bottom: 66px;
}
footer .bottom1_list {
  display: flex;
  padding-bottom: 30px;
}
footer .bottom1_list_item {
  margin-right: 12px;
  padding-right: 12px;
  position: relative;
}
footer .bottom1_list_item::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 12px;
  background-color: #8596c2;
  top: 10px;
  right: 0;
}
footer .bottom1_list_item:nth-last-of-type(1)::before {
  display: none;
}
footer .bottom1_list_item a {
  font-size: 16px;
  color: #8596c2;
}
footer .white a {
  color: #fff;
  font-weight: 400;
}
footer .bottom2 {
  padding-bottom: 30px;
}
footer .address {
  display: flex;
}
footer .address_list {
  font-size: 14px;
  color: #8596c2;
  margin-right: 10px;
  padding-right: 10px;
}
footer .address_list.num {
  position: relative;
}
footer .address_list.num::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 12px;
  background-color: #8596c2;
  top: 6px;
  right: 0;
}
footer .copy {
  font-size: 14px;
  color: #8596c2;
}
footer .select_box {
  position: relative;
  font-size: 16px;
  width: 200px;
  flex: 0 0 auto;
  z-index: 2;
}
footer .select_box .current {
  padding-left: 18px;
  height: 50px;
  position: relative;
  color: #fff;
  background-color: #182d65;
  border: 1px solid #8596c2;
  border-radius: 0;
  width: 100%;
  text-align: left;
  font-weight: 500;
  z-index: 5;
  display: inline-flex;
  align-items: center;
}
footer .select_box .current:after {
  content: "";
  width: 12px;
  height: 8px;
  right: 22px;
  position: absolute;
  top: 50%;
  background: url("../images/select_arrow.png") no-repeat center/100% auto;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
}
footer .select_box .option-dropdown {
  position: absolute;
  left: 0;
  bottom: 49px;
  background-color: #182d65;
  border-radius: 0;
  width: 0;
  overflow: hidden;
  transform: translateY(0px);
  transition: transform 0.3s ease;
}
footer .select_box.is-active .current {
  border: 1px solid #8596c2;
  border-radius: 0;
}
footer .select_box.is-active .current:after {
  transform: translateY(-50%) scaleY(-1);
}
footer .select_box.is-active .option-dropdown {
  width: 100%;
  border: 1px solid #8596c2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transform: translateY(0);
  padding: 10px 0;
}
footer .select_box.is-active .option-dropdown::-webkit-scrollbar {
  width: 5px;
}
footer .select_box.is-active .option-dropdown::-webkit-scrollbar-track {
  background-color: transparent;
}
footer .select_box.is-active .option-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}
footer .select_box.is-active .option-dropdown a {
  color: #fff;
  padding: 10px 10px 10px 18px;
}

.footer_email_wrap {
  margin-bottom: 120px;
}

.footer_email_text {
  font-size: 16px;
  padding-bottom: 30px;
  color: #333;
  line-height: 2;
}

.footer_wrap {
  margin-bottom: 180px;
}

.footer_text_inner {
  padding-bottom: 40px;
}

.footer_text {
  font-size: 16px;
  color: #333;
  text-indent: -20px;
  padding-left: 20px;
  line-height: 32px;
}

.p_text {
  text-indent: 0;
  padding-left: 0;
  padding-bottom: 40px;
}

.footer_terms_privacy_title {
  display: block;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
}

.button_top {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 160px;
  bottom: 40px;
  background: url(../images/top_button.png) no-repeat;
  display: none;
  z-index: 999;
}
.button_top.show {
  display: block;
}

.tab_link {
  display: flex;
  padding-left: 1px;
  border-bottom: 2px solid #182d65;
}
.tab_link li {
  border: 1px solid #dadada;
  border-bottom: none;
  width: 170px;
  height: 54px;
  margin-left: -1px;
}
.tab_link li.is-active {
  border: 1px solid transparent;
  background-color: #182d65;
}
.tab_link li.is-active a {
  font-weight: bold;
  color: #fff;
}
.tab_link a {
  display: block;
  width: 100%;
  text-align: center;
  color: #999;
  font-size: 20px;
  line-height: 54px;
}

.pagination {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.pagination li {
  display: inline-block;
  vertical-align: top;
}
.pagination li a {
  width: 41px;
  height: 41px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #111;
  border: 1px solid #dadada;
  padding-top: 1px;
  font-family: "Montserrat", sans-serif;
}
.pagination li.is-current a {
  color: #fff;
  background-color: #182d65;
}
.pagination li.page {
  position: relative;
  margin: 0 8px;
}
.pagination li.page a {
  font-size: 0;
}
.pagination li.page a:after {
  content: "";
  background: no-repeat left center/auto 90%;
  height: 16px;
}
.pagination li.page.prev a:after, .pagination li.page.next a:after {
  width: 8px;
  height: 14px;
  background-image: url(../images/page_next.png);
}
.pagination li.page.prev a:after {
  transform: rotate(-180deg);
}

.main__visual {
  background: url("../images/main_bg.jpg") no-repeat;
  background-size: cover;
  height: 900px;
  min-height: 100vh;
}
.main__visual .main-content {
  padding-top: 100px;
  color: #fff;
}
.main__visual .main-content .main__inner {
  margin-top: 105px;
}
.main__visual .main-content .main__inner .headline {
  font-size: 60px;
  margin-bottom: 60px;
}
.main__visual .main-content .main__inner .desc {
  font-size: 24px;
}
.main__job-posting .main__inner {
  padding-top: 0;
}
.main__job-posting .job_notice-list li {
  width: 33.33%;
}
.main__job-posting .job_notice-list .title {
  color: #111;
}
.main__job-posting .job_notice-list .item .date {
  padding: 0;
  color: #333;
}
.main__job-posting .button_wrap {
  padding-top: 60px;
}
.main__notice .notice_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #111;
  padding-bottom: 20px;
}
.main__notice .notice_top .section_title {
  font-size: 40px;
  color: #111;
  font-weight: bold;
}
.main__notice .notice_top .more_btn {
  color: #111;
  position: relative;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 44px;
}
.main__notice .notice_top .more_btn:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-33%);
  right: 25px;
  width: 8px;
  height: 11px;
  background: url("../images/more_arrow.png") no-repeat center/100% auto;
}
.main__notice .notice_list {
  padding-bottom: 100px;
}
.main__notice .notice_list .item {
  border-bottom: 1px solid #eee;
}
.main__notice .notice_list .item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}
.main__notice .notice_list .item .title {
  width: 80%;
  color: #111;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main__notice .notice_list .item .date {
  width: 20%;
  color: #888;
  text-align: right;
}
.main__notice .notice_list .item.emphasis a {
  padding: 40px 25px;
}
.main__notice .notice_list .item.emphasis .title {
  font-weight: bold;
  font-size: 28px;
}
.main__notice .notice_list .item .date {
  font-size: 18px;
}

.layer {
  position: fixed;
  left: 0;
  top: 0;
}
.layer_inner {
  position: relative;
  max-height: 80vh;
  background-color: #fff;
  width: 600px;
  transform: translateY(30px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}
.layer .close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 30px;
  height: 30px;
  font-size: 0;
  z-index: 9999;
  background: url(../images/layer_close.png) no-repeat center/100% auto;
}
.layer.is-hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
.layer.is-open {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.layer.is-open .layer_inner {
  transform: translateY(0);
  transition: transform 0.3s ease;
}
.layer .top {
  padding: 40px;
  border-bottom: 1px solid #dadada;
  line-height: 1;
}
.layer .top .title {
  font-size: 34px;
  color: #182d65;
  font-weight: 700;
}
.layer .layer_btn {
  margin-top: 60px;
  text-align: center;
  margin-bottom: 40px;
}
.layer .layer_btn .primary {
  width: 200px;
  height: 60px;
  font-size: 16px;
}
.layer .input_inner {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.layer label {
  font-size: 16px;
  width: 145px;
  font-weight: 500;
}
.layer .input_text {
  width: 324px;
  height: 42px;
  border: 1px solid #999;
  padding: 0 19px 3px;
}
.layer .input_text::placeholder {
  font-size: 14px;
  color: #999;
}
.layer .red_text {
  display: none;
  color: #e92d2d;
  font-size: 14px;
  margin-left: 20px;
  padding-top: 10px;
}
.layer .red_text.is-active {
  display: block;
}

.layer_title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 70px 0;
}

.login_form_inner {
  padding: 80px 65px;
}
.login_form {
  width: 100%;
}
.login_find {
  text-align: center;
}
.login_find a {
  font-size: 16px;
  color: #999;
  margin-right: 20px;
}
.login_find .line {
  position: relative;
}
.login_find .line::before {
  content: "";
  top: 7px;
  right: -14px;
  position: absolute;
  width: 1px;
  height: 12px;
  background-color: #999;
}
.login_bottom {
  background-color: #f8f8f8;
  padding: 40px 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login_bottom p {
  font-size: 16px;
  font-weight: 700;
}
.login_bottom a {
  width: 120px;
  height: 42px;
  color: #000;
  display: block;
  text-align: center;
  padding-top: 5px;
}

.find_id_form_inner {
  padding: 0 60px 60px 60px;
}
.find_id_form label {
  width: 115px;
}
.find_id_form .id_text {
  border: none;
  width: 190px;
}
.find_id_form .input_name {
  width: 364px;
}
.find_id_form .input_tel {
  width: 244px;
}
.find_id_form .button {
  width: 110px;
  margin-left: 10px;
  height: 42px;
  font-size: 14px;
}
.find_id_form .layer_btn {
  margin-top: 78px;
}
.find_id_form .continue {
  display: flex;
}
.find_id_form .text-box {
  display: flex;
  align-items: center;
}
.find_id_form .auth_number {
  display: flex;
  align-items: center;
  border: 1px solid #999;
  width: 244px;
}
.find_id_form .time-wrap {
  width: 54px;
  text-align: center;
  color: #e92d2d;
  font-size: 14px;
}
.find_id_form .time-wrap.hide {
  display: none;
}

.find_info {
  width: 481px;
  background-color: #f8f8f8;
  margin: 0 auto;
  text-align: center;
  padding: 45px 0;
}
.find_info p {
  font-size: 20px;
  color: #1a2f66;
  font-weight: 600;
}
.find_info .name {
  margin-bottom: 10px;
}

.layer_id_success .layer_btn {
  margin: 80px 0 90px;
}
.layer_id_success .layer_btn .button {
  display: inline-block;
  width: 200px;
  height: 60px;
  padding-top: 15px;
  font-size: 16px;
}
.layer_id_success .layer_btn .secondary {
  color: #000;
  margin-right: 20px;
}
.layer_id_success .line {
  position: relative;
}
.layer_id_success .line::before {
  content: "";
  top: 7px;
  right: -14px;
  position: absolute;
  width: 1px;
  height: 12px;
  background-color: #999;
}

.find_pw_form_inner {
  padding: 0 60px;
}
.find_pw_form_inner .layer_btn {
  margin-top: 90px;
  margin-bottom: 100px;
}
.find_pw_form .input_text {
  width: 364px;
}
.find_pw_form label {
  width: 115px;
}

.reset_pw_form_inner {
  padding: 0 60px;
}
.reset_pw_form_inner .layer_btn {
  margin-top: 90px;
  margin-bottom: 100px;
}
.reset_pw_form .input_inner {
  align-items: flex-start;
}
.reset_pw_form .input_text {
  width: 364px;
}
.reset_pw_form label {
  width: 115px;
  padding-top: 10px;
}
.reset_pw_form .layer_btn {
  margin-top: 30px;
  margin-bottom: 90px;
  margin-left: 0;
}
.reset_pw_form .secondary {
  margin-right: 20px;
}
.reset_pw_form .button {
  width: 200px;
  height: 60px;
  font-size: 16px;
}

.layer_email .find_text {
  padding: 80px 0 40px;
}
.layer_email .layer_title {
  padding: 80px 0 40px;
}
.layer_email .sub_text {
  text-align: center;
  font-size: 20px;
  padding-bottom: 80px;
}
.layer_email .email_form_inner {
  padding: 0 60px;
}
.layer_email .email_form label {
  width: 125px;
}
.layer_email .email_form .input_text {
  width: 330px;
}
.layer_email .email_form .layer_btn {
  margin-top: 60px;
  margin-bottom: 80px;
}

.layer_button button {
  padding: 10px;
  border: 1px solid #000;
}

.application_form {
  padding-bottom: 100px;
  font-size: 14px;
}
.application_form form .item {
  white-space: nowrap;
}
.application_form .step {
  padding: 92px 20px 70px 30px;
  display: flex;
}
.application_form .step li {
  text-align: center;
  width: 225px;
}
.application_form .step li.current .number {
  background-color: #4c75e2;
}
.application_form .step li.current .number::after {
  content: "";
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 223px;
  height: 94px;
  background: url("../images/step_active_dotted.png") no-repeat center/100% auto;
}
.application_form .step li.current .number::before {
  display: none;
}
.application_form .step li.current .title {
  color: #111;
  font-weight: bold;
}
.application_form .step li:last-child .number::before {
  display: none;
}
.application_form .step li:last-child.current .number::after {
  background: url("../images/last_step_active_dotted.png") no-repeat center/100% auto;
  width: 94px;
  height: 94px;
}
.application_form .step .number {
  position: relative;
  display: inline-block;
  background-color: #dadada;
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  border-radius: 50%;
}
.application_form .step .number:before {
  content: "";
  position: absolute;
  background-color: #dadada;
  height: 1px;
  width: 113px;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 20px);
}
.application_form .step .title {
  margin-top: 28px;
  color: #dadada;
  font-size: 18px;
}
.application_form .button_wrap {
  padding-top: 50px;
}
.application_form .require_notice {
  font-size: 14px;
  color: #333;
  position: absolute;
  right: 0;
  top: 30px;
}
.application_form .require_notice p {
  position: relative;
}
.application_form .require {
  font-size: 14px;
  color: #e92d2d;
  padding-right: 3px;
  position: absolute;
  left: -8px;
  top: 0;
}
.application_form .table_category {
  font-size: 14px;
  position: relative;
}
.application_form .table_category .table_title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.application_form .table_category select {
  font-size: 14px;
}
.application_form .table_category select option {
  color: #111;
}
.application_form .table_category .button.secondary {
  margin-left: 12px;
  width: 140px;
  height: 42px;
  font-size: 14px;
  padding: 0 10px;
}
.application_form .table_category .button.secondary.add_list {
  margin: 10px 0 0 0;
}
.application_form .top_border {
  border-top: 2px solid #182d65;
}
.application_form .category {
  display: flex;
  border-bottom: 1px solid #dadada;
  align-items: center;
}
.application_form .category .title {
  flex-shrink: 0;
  font-weight: bold;
  background: #f8f8f8;
  width: 145px;
  font-size: 14px;
  padding-left: 28px;
  min-height: 60px;
  display: flex;
  align-items: center;
  align-self: normal;
}
.application_form .category .title .text {
  position: relative;
}
.application_form .category .require {
  font-weight: bold;
}
.application_form .category .item {
  padding: 10px 20px;
}
.application_form .category .item label {
  font-size: 14px;
  color: #111;
}
.application_form .category .item .inner_label {
  position: relative;
  margin-right: 16px;
  font-size: 14px;
  color: #111;
}
.application_form .category .item input,
.application_form .category .item textarea {
  border: 1px solid #dadada;
  height: 42px;
  padding: 0 20px;
  font-size: 14px;
}
.application_form .category .item input::placeholder,
.application_form .category .item textarea::placeholder {
  color: #999;
}
.application_form .category .item input:read-only,
.application_form .category .item textarea:read-only {
  border: none;
  padding: 0 2px;
}
.application_form .category .item input:read-only:focus,
.application_form .category .item textarea:read-only:focus {
  outline: none;
}
.application_form .category .item input.user_name,
.application_form .category .item textarea.user_name {
  font-weight: bold;
}
.application_form .category .item textarea {
  resize: none;
  overflow-y: auto;
  margin: 10px 0;
  padding: 20px;
}
.application_form .category .item textarea.detail_spot {
  height: 200px;
}
.application_form .category .item textarea.detail_spot {
  height: 200px;
}
.application_form .category .item textarea.detail_project {
  height: 280px;
}
.application_form .category .item .dash {
  margin: 0 10px;
}
.application_form .category .item .unit {
  margin-left: 10px;
  color: #444;
}
.application_form .category .item.multi {
  display: flex;
  align-items: center;
}
.application_form .category .item.multi select {
  margin-right: 10px;
}
.application_form .category .item.single_line {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.application_form .category .item_wrap {
  padding-top: 10px;
}
.application_form .category .item_wrap .line {
  display: flex;
  align-items: center;
}
.application_form .category .item_wrap .item {
  padding-top: 0;
}
.application_form .category select {
  border: 1px solid #dadada;
  border-right: none;
  height: 42px;
  padding: 0 17px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/select_box_arrow.png") no-repeat;
  background-color: #fff;
  background-position: center right;
  color: #999;
  font-size: 16px;
}
.application_form .category select:focus {
  color: #111;
}
.application_form .category.no_bottom_line {
  border-bottom: none;
}
.application_form .category.simple .item {
  display: flex;
  align-items: center;
  padding: 0 29px 0 0;
}
.application_form .category.simple .item .title {
  margin-right: 20px;
}
.application_form .category.simple .w_full {
  flex: 1;
}
.application_form .category.simple .w_50 {
  width: 50%;
}
.application_form .category.simple .w_25 {
  width: 25%;
}
.application_form .category.textarea .item {
  padding-right: 0;
}
.application_form .form_agree .agree {
  padding-bottom: 50px;
}
.application_form .form_agree .agree:first-of-type {
  margin-top: 60px;
}
.application_form .form_agree .agree .input-checkbox {
  padding-bottom: 28px;
}
.application_form .form_agree .agree .input-checkbox label,
.application_form .form_agree .agree .input-checkbox .agree_title {
  font-weight: bold;
  font-size: 20px;
}
.application_form .form_agree .agree_desc {
  overflow-y: auto;
  border: 1px solid #dadada;
  padding: 28px;
  font-size: 16px;
  color: #333;
}
.application_form .form_agree .agree_desc.desc_1 {
  height: 284px;
}
.application_form .form_agree .agree_desc.desc_2 {
  height: 252px;
}
.application_form .form_agree .message {
  text-align: center;
}
.application_form .form_basic .filter {
  background-color: #f8f8f8;
  padding: 30px 30px 10px 30px;
  margin-bottom: 76px;
}
.application_form .form_basic .filter_title {
  width: 80px;
  position: relative;
  font-weight: bold;
}
.application_form .form_basic .filter_title:after {
  content: "";
  width: 1px;
  height: 12px;
  background-color: #999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.application_form .form_basic .filter .filter_item,
.application_form .form_basic .filter .desc {
  margin-left: 19px;
}
.application_form .form_basic .filter .w_180 {
  width: 13.4%;
}
.application_form .form_basic .filter .w_180 select {
  width: 100%;
}
.application_form .form_basic .filter .w_300 {
  width: 22.3%;
}
.application_form .form_basic .filter .w_300 select {
  width: 100%;
}
.application_form .form_basic .filter .category {
  border: none;
  padding-bottom: 20px;
}
.application_form .form_basic .profile {
  position: relative;
  border: 1px solid #111;
  width: 224px;
  height: 284px;
}
.application_form .form_basic .profile .files {
  width: 100%;
  height: 100%;
}
.application_form .form_basic .profile_box {
  width: 100%;
  height: 100%;
  padding: 0 7px 0;
  display: flex;
  align-items: center;
}
.application_form .form_basic .profile_box p {
  line-height: 20px;
  font-size: 12px;
  color: #999;
  text-align: center;
}
.application_form .form_basic .profile_text {
  padding-top: 175px;
  position: relative;
}
.application_form .form_basic .profile_text:after {
  content: "";
  position: absolute;
  top: 0;
  left: 17%;
  background: url("../images/profile_img.png") no-repeat;
  width: 146px;
  height: 153px;
}
.application_form .form_basic .profile .image_view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.application_form .form_basic .profile .image_view img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}
.application_form .form_basic .profile .image_view .del {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0;
  width: 24px;
  height: 24px;
  background: url("../images/icon_close.svg") no-repeat center/100% auto;
}
.application_form .form_basic .info_wrap {
  margin-left: 20px;
  width: calc(100% - 215px);
}
.application_form .form_basic .info {
  width: 100%;
}
.application_form .form_basic .info .category {
  width: 100%;
}
.application_form .form_basic .info .category.military .item_wrap {
  padding-bottom: 10px;
  padding-left: 10px;
}
.application_form .form_basic .info .category.military .inner_label {
  font-weight: bold;
}
.application_form .form_basic .info .category.military .military_label {
  padding-right: 34px;
}
.application_form .form_basic .info .category.prev_income .inner_label {
  font-weight: bold;
  color: #444;
}
.application_form .form_basic .info .category.prev_income .item:first-of-type {
  padding-right: 7px;
}
.application_form .form_basic .info .category.prev_income .item:last-of-type {
  padding-left: 7px;
}
.application_form .form_basic .info.info_1 {
  display: flex;
  align-items: flex-start;
}
.application_form .form_basic .info.info_2 {
  border-top: 1px solid #dadada;
}
.application_form .form_basic .info.info_3 {
  margin-top: 20px;
}
.application_form .form_basic .w_284 {
  width: 284px;
}
@media (max-width: 1400px) {
  .application_form .form_basic .w_284 {
    width: 250px;
  }
}
.application_form .form_basic .w_300 {
  width: 300px;
}
@media (max-width: 1400px) {
  .application_form .form_basic .w_300 {
    width: 250px;
  }
}
.application_form .form_carrer .table_category {
  margin-bottom: 77px;
}
.application_form .form_carrer .table_category .button.secondary {
  width: 120px;
}
.application_form .form_carrer .category.major .item {
  padding-right: 60px;
}
.application_form .form_carrer .category.record .total_score {
  padding-right: 10px;
}
.application_form .form_carrer .category.record .score {
  padding-left: 10px;
}
.application_form .form_carrer .calender_wrap {
  display: flex;
  align-items: center;
}
.application_form .form_carrer .calender_wrap .calendar {
  position: relative;
}
.application_form .form_carrer .calender_wrap .tui-datepicker {
  z-index: 99;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}
.application_form .form_carrer select.level {
  margin-left: 10px;
}
.application_form .form_carrer .w_260 {
  width: 260px;
}
@media (max-width: 1400px) {
  .application_form .form_carrer .w_260 {
    width: 220px;
  }
}
.application_form .form_carrer .w_160 {
  width: 160px;
}
@media (max-width: 1400px) {
  .application_form .form_carrer .w_160 {
    width: 120px;
  }
}
.application_form .form_carrer .w_360 {
  width: 360px;
}
@media (max-width: 1400px) {
  .application_form .form_carrer .w_360 {
    width: 300px;
  }
}
.application_form .form_carrer .w_full {
  width: 1061px;
}
@media (max-width: 1400px) {
  .application_form .form_carrer .w_full {
    width: 960px;
  }
}
.application_form .form_introduce .table_category {
  margin-bottom: 80px;
}
.application_form .form_introduce .table_category > label {
  font-size: 20px;
  font-weight: bold;
}
.application_form .form_introduce .table_category .require {
  padding: 0;
  font-size: 20px;
  position: static;
}
.application_form .form_introduce .table_category.last {
  margin-bottom: 0;
}
.application_form .form_introduce textarea {
  margin-top: 30px;
  width: 100%;
  padding: 32px;
  border: 1px solid #dadada;
  font-size: 16px;
  resize: none;
  height: 286px;
  overflow: auto;
}
.application_form .form_introduce textarea::placeholder {
  color: #999;
}
.application_form .form_introduce .num_characters {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  color: #333;
  padding-top: 12px;
  font-family: "Montserrat", sans-serif;
}
.application_form .form_submit .submit_message {
  background-color: #f8f8f8;
  text-align: center;
  padding: 45px;
}
.application_form .form_submit .submit_message strong {
  font-size: 20px;
  color: #111;
  margin-bottom: 30px;
  display: inline-block;
}
.application_form .form_submit .submit_message p {
  font-size: 16px;
  color: #333;
}
.application_form .search_box {
  position: relative;
}
.application_form .search_box input {
  padding-right: 40px !important;
}
.application_form .search_box.is-active .delete_btn,
.application_form .search_box.is-active .search_list {
  display: block;
}
.application_form .search_box .delete_btn {
  display: none;
  position: absolute;
  font-size: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  background: url("../images/icon_close.svg") no-repeat center/100% auto;
  width: 20px;
  height: 20px;
}
.application_form .search_box .search_list {
  position: absolute;
  display: none;
  background: #fff;
  border-top: #182d65;
  width: 100%;
  top: calc(100% + 5px);
  z-index: 9;
  border: 1px solid skyblue;
  max-height: 200px;
  overflow-y: auto;
}
.application_form .search_box .search_list li {
  display: block;
}
.application_form .search_box .search_list li button {
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
}
.application_form .search_box .search_list li:hover,
.application_form .search_box .search_list li .on {
  background-color: #f8f8f8;
}
.application_form .search_box .search_list li.none {
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
}
.application_form mark {
  color: #007aff;
  font-style: normal;
  background-color: initial;
}

.main_button {
  margin-bottom: 100px;
  text-align: center;
}
.main_button .button {
  display: block;
  color: #fff;
  background-color: #1a2f66;
  height: 60px;
  width: 200px;
  margin: 0 auto;
}

.join_content {
  display: flex;
  width: 100%;
}
.join_content .title {
  width: 190px;
  font-size: 20px;
  font-weight: bold;
}
.join_content .red_text {
  color: #e92d2d;
  font-size: 14px;
}
.join_content_01 {
  margin-bottom: 80px;
}
.join_content_01 .form__inner {
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
  width: 100%;
  border-top: 2px solid #1a2f66;
  border-bottom: 1px solid #dadada;
  margin-top: 5px;
}
.join_content_01 .form__inner:before {
  content: "";
  width: 145px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f8f8f8;
}
.join_content_01 .form__inner .item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
}
.join_content_01 .form__inner .item .red_text {
  margin-bottom: 10px;
}
.join_content_01 .form__inner .double_check .button.secondary {
  width: 140px;
  margin-left: 10px;
  height: 40px;
  font-size: 14px;
}
.join_content_01 .form__inner .double_check .button.trans {
  width: 140px;
  margin-left: 10px;
  height: 40px;
  font-size: 14px;
  background-color: #999;
  color: #fff;
}
.join_content_01 .form__inner .label {
  width: 145px;
  font-size: 16px;
  color: #333;
  padding: 0 26px;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: 500;
}
.join_content_01 .form__inner .red_text {
  margin-left: 57px;
  padding-top: 10px;
  display: none;
  transition: all 0.1s ease-out;
}
.join_content_01 .form__inner .red_text.is-active {
  display: block;
}
.join_content_01 .form__inner .input-text {
  width: 600px;
  margin-left: 40px;
  border: 1px solid #999;
  padding: 0 20px;
  height: 40px;
  color: #111;
  font-size: 14px;
}
.join_content_01 .form__inner .input-text::placeholder {
  color: #999;
  font-size: 14px;
}
.join_content_02 {
  margin-bottom: 80px;
}
.join_content_02 .checkbox__inner {
  width: 100%;
  display: flex;
  border-top: 2px solid #1a2f66;
  border-bottom: 1px solid #dadada;
  margin-top: 5px;
}
.join_content_02 .checkbox__inner .allcheck {
  width: 145px;
  padding: 20px 0 0 26px;
  background-color: #f8f8f8;
  font-size: 16px;
  color: #333;
}
.join_content_02 .checkbox__inner .allcheck input[id=allAgree] {
  display: none;
}
.join_content_02 .checkbox__inner .allcheck input[id=allAgree]:checked + label::before {
  background: url(../images/checked.png) no-repeat center/100% auto;
}
.join_content_02 .checkbox__inner .allcheck label {
  position: relative;
  padding-left: 30px;
  font-weight: 500;
}
.join_content_02 .checkbox__inner .allcheck label::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 0;
  background: url(../images/checkbox.png) no-repeat center/100% auto;
}
.join_content_02 .checkbox__inner .checkbox {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  padding-top: 22px;
  width: 390px;
}
.join_content_02 .checkbox__inner .checkbox .check_inner {
  padding-bottom: 22px;
  position: relative;
}
.join_content_02 .checkbox__inner .checkbox .check_inner .red_text {
  font-size: 16px;
  margin: 0 5px;
}
.join_content_02 .checkbox__inner .checkbox input {
  display: none;
}
.join_content_02 .checkbox__inner .checkbox input:checked + label::before {
  background: url(../images/checked.png) no-repeat center/100% auto;
}
.join_content_02 .checkbox__inner .checkbox label {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
}
.join_content_02 .checkbox__inner .checkbox label::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 0;
  background: url(../images/checkbox.png) no-repeat center/100% auto;
}
.join_content_02 .checkbox__inner .checkbox .Terms {
  font-size: 14px;
  color: #999;
  border-bottom: 1px solid #999;
  padding-right: 20px;
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
}
.join_content_02 .checkbox__inner .checkbox .Terms::after {
  content: "";
  width: 11px;
  height: 8px;
  position: absolute;
  top: 7px;
  right: 0px;
  background: url(../images/terms_arrow.png) no-repeat center/100% auto;
}

.success_content {
  text-align: center;
  padding-bottom: 80px;
}
.success_title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  padding-bottom: 33px;
}
.success_text {
  font-size: 16px;
  color: #333;
}
.success_button {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 100px;
}
.success_button a {
  display: inline-block;
  width: 200px;
  height: 60px;
  margin-right: 20px;
  font-size: 16px;
  padding-top: 15px;
}
.success_button .secondary {
  color: #000;
}

.sub-tab_link {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.sub-tab_link li {
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
}
.sub-tab_link li + li:before {
  content: "";
  width: 1px;
  height: 12px;
  display: block;
  background-color: #dadada;
  margin: 0 20px;
}
.sub-tab_link li.is-active a {
  color: #182d65;
  position: relative;
  font-weight: bold;
  z-index: 1;
}
.sub-tab_link li.is-active a::after {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  left: -5px;
  bottom: 0px;
  z-index: -1;
  background-color: #d3d8e7;
  display: block;
  padding: 0 5px;
}
.sub-tab_link a {
  color: #999;
}

.job_notice {
  padding-top: 70px;
}
.job_notice .info {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-bottom: 15px;
}
.job_notice .type {
  color: #1a2f66;
}
.job_notice .type.always {
  color: #a0775b;
}
.job_notice .type + .company {
  display: flex;
  align-items: center;
}
.job_notice .type + .company:before {
  content: "";
  width: 1px;
  height: 16px;
  margin: 0 8px;
  background-color: #1a2f66;
}
.job_notice .company {
  color: #999;
}
.job_notice .title,
.job_notice .date,
.job_notice .d-day {
  font-family: "Montserrat", "Noto Sans KR", Helvetica, "malgun gothic", "Apple SD Gothic Neo", "Microsoft NeoGothic", "Droid sans", sans-serif;
}
.job_notice .title {
  display: block;
  font-size: 24px;
}
.job_notice .date {
  color: #999;
}

.job_notice-list {
  margin-top: 25px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}
.job_notice-list li {
  width: 50%;
  padding-right: 20px;
  padding-bottom: 20px;
}
.job_notice-list li:hover .item {
  padding: 6px;
  background: rgb(40, 82, 194);
  background: linear-gradient(160deg, rgb(40, 82, 194) 0%, rgb(24, 45, 101) 70%, rgb(24, 45, 101) 100%);
  border-color: transparent;
  transition: background-color 0.3s ease;
}
.job_notice-list .item {
  padding: 6px;
  height: 250px;
  border: 1px solid #dadada;
}
.job_notice-list .item_box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 26px;
  position: relative;
  font-size: 18px;
}
.job_notice-list .item .info {
  padding-right: 68px;
}
.job_notice-list .item .title {
  height: 78px;
  margin-bottom: 27px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}
.job_notice-list .item .d-day {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  color: #182d65;
}
.job_notice-list .item .date {
  padding-right: 150px;
}
.job_notice-list .item .button {
  position: absolute;
  right: 35px;
  bottom: 22px;
  width: 140px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}
.job_notice-list .item .cancel {
  pointer-events: none;
}

.job_notice-detail {
  margin-top: 25px;
  border-top: 1px solid #dadada;
}
.job_notice-detail .detail_info {
  padding: 25px 20px;
  font-size: 18px;
  border-bottom: 1px solid #dadada;
}
.job_notice-detail .detail_info .title {
  font-size: 24px;
  padding-bottom: 24px;
}
.job_notice-detail .detail_info .d-day {
  font-size: 20px;
  padding: 4px 12px;
  background-color: #182d65;
  color: #fff;
  font-weight: bold;
  margin-left: 16px;
}
.job_notice-detail .detail_contents {
  padding: 25px 20px;
  border-bottom: 1px solid #dadada;
  color: #111;
  font-size: 16px;
}
.job_notice-detail .detail_contents p {
  margin-bottom: 25px;
}
.job_notice-detail .detail_group {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}
.job_notice-detail .detail_group .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  font-size: 16px;
}
.job_notice-detail .detail_group .secondary {
  color: #000;
}

.recruit_inner {
  padding-top: 43px;
}

.recruit_top {
  position: relative;
  margin-bottom: 121px;
  width: 100%;
}

.recruit_textbox {
  width: 688px;
  height: 357px;
  padding-left: 30px;
  background-color: #213e89;
  color: #fff;
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recruit_title {
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 25px;
}

.recruit_sub_title {
  font-size: 30px;
  padding-bottom: 30px;
}

.recruit_dec {
  font-size: 16px;
  font-weight: 100;
}

.img_textbox {
  display: flex;
  justify-content: flex-end;
}

.recruit_bottom {
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
}

.culture_box {
  display: flex;
  margin-bottom: 133px;
}

.culture_list {
  width: 413px;
  border: 1px solid #dadada;
  border-top: 4px solid #1a2f66;
  margin-right: 80px;
  position: relative;
  padding: 30px 0 30px 30px;
}
.culture_list::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/plus.png) no-repeat center/100% auto;
}
.culture_list:nth-last-of-type(1) {
  margin-right: 0px;
}
.culture_list:nth-last-of-type(1)::after {
  display: none;
}

.culture_list_title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 30px;
  color: #182d65;
}

.culture_list_text {
  font-size: 16px;
}

.culture_slogan {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 42px;
}

.culture_img_box {
  display: flex;
  justify-content: center;
  margin-bottom: 114px;
}

.welfare_top {
  margin-bottom: 142px;
}

.welfare_inner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 162px;
  width: 100%;
  padding-left: 30px;
}

.welfare_list {
  display: flex;
  flex-direction: column;
  width: calc(33.3% - 53px);
  position: relative;
  margin-right: 79px;
  padding-bottom: 80px;
  margin-bottom: 80px;
  border-bottom: 1px solid #dadada;
}
.welfare_list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: no-repeat center/100% auto;
}
.welfare_list:nth-of-type(1)::before {
  width: 58px;
  height: 41px;
  background: url(../images/welfare_list1.png);
}
.welfare_list:nth-of-type(2)::before {
  width: 38px;
  height: 41px;
  background: url(../images/welfare_list2.png);
}
.welfare_list:nth-of-type(3) {
  margin-right: 0;
}
.welfare_list:nth-of-type(3)::before {
  width: 58px;
  height: 41px;
  background: url(../images/welfare_list3.png);
}
.welfare_list:nth-of-type(4)::before {
  width: 45px;
  height: 32px;
  background: url(../images/welfare_list4.png);
}
.welfare_list:nth-of-type(5)::before {
  width: 49px;
  height: 40px;
  background: url(../images/welfare_list5.png);
}
.welfare_list:nth-of-type(6) {
  margin-right: 0;
}
.welfare_list:nth-of-type(6)::before {
  width: 37px;
  height: 42px;
  background: url(../images/welfare_list6.png);
}
.welfare_list:nth-of-type(7)::before {
  width: 48px;
  height: 40px;
  background: url(../images/welfare_list7.png);
}
.welfare_list:nth-of-type(8)::before {
  width: 46px;
  height: 43px;
  background: url(../images/welfare_list8.png);
}

.welfare_title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 29px;
  padding-top: 60px;
}

.welfare_dec {
  font-size: 16px;
}

.second {
  padding-left: 8px;
}

.job_tab_wrap {
  padding-top: 65px;
  padding-bottom: 30px;
  display: flex;
  padding-left: 20px;
}
.job_tab_wrap li {
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}
.job_tab_wrap li::after {
  content: "";
  width: 1px;
  height: 14px;
  background-color: #e5e5e5;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
}
.job_tab_wrap li:nth-last-of-type(1)::after {
  display: none;
}
.job_tab_wrap a {
  font-size: 20px;
  color: #999;
}
.job_tab_wrap a .line {
  display: none;
}
.job_tab_wrap .is-active a {
  color: #182d65;
  position: relative;
  font-weight: bold;
  z-index: 1;
}
.job_tab_wrap .is-active a::after {
  content: "";
  width: 100%;
  height: 10px;
  position: absolute;
  left: -5px;
  bottom: 0px;
  z-index: -1;
  background-color: #d3d8e7;
  display: block;
  padding: 0 5px;
}

.newcomer {
  position: relative;
}

.newcomer_top {
  margin-bottom: 50px;
}

.newcomer_list {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  padding-left: 136px;
}
.newcomer_list:nth-of-type(1) {
  padding-left: 0;
}

.newcomer_bottom {
  padding-bottom: 80px;
}

.bottom_inner {
  padding-bottom: 55px;
}

.bottom_list {
  display: flex;
}

.bottom_title {
  font-size: 20px;
  font-weight: bold;
  padding-left: 25px;
  width: 245px;
  color: #333;
}

.bottom_text {
  font-size: 16px;
  color: #111;
}

.career {
  position: relative;
}

.career_top {
  margin-bottom: 50px;
}

.career_list {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  padding-left: 136px;
}
.career_list:nth-of-type(1) {
  padding-left: 0;
}

.career_bottom {
  padding-bottom: 70px;
}

.career_bottom_list {
  font-size: 16px;
  color: #111;
}

.job_section {
  display: none;
}
.job_section.on {
  display: block;
}
.job_section img {
  width: 100%;
}
.job_section_top {
  margin-bottom: 76px;
}
.job_section_top.wrap {
  display: flex;
}
.job_section_title {
  margin-left: 20px;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 37px;
  margin-top: 30px;
  color: #182d65;
}
.job_section_bottom {
  padding-bottom: 143px;
  padding-left: 20px;
}
.job_section .sub_title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  width: 160px;
}
.job_section .desc {
  font-size: 16px;
  color: #111;
}
.job_section .bottom {
  margin-right: -40px;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
}
.job_section .item {
  width: 50%;
  padding-right: 40px;
}
.job_section .bottom1 {
  padding-bottom: 75px;
}
.job_section .bottom1 .item_inner {
  display: flex;
}
.job_section .bottom1 .item .desc,
.job_section .bottom1 .item .desc_wrap {
  flex: 1;
}
.job_section .bottom2 {
  padding-top: 15px;
}
.job_section .bottom2 .item {
  margin-bottom: 40px;
}
.job_section .bottom2 .item_inner {
  height: 100%;
  border: 1px solid #dadada;
  border-top: 4px solid #1a2f66;
  position: relative;
  padding: 30px;
}
.job_section .bottom2 .desc_wrap {
  padding-top: 25px;
}
.job_section .bottom2 .sub_title {
  width: 100%;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}
.job_section .tab_inner img {
  margin-left: -20px;
  width: calc(100% + 20px);
  margin-bottom: 70px;
}
.job_section .tab_inner .job_section_title {
  font-size: 30px;
  margin-right: 80px;
}
.job_section .tab_inner_button {
  display: flex;
}
.job_section .tab_inner_button button {
  color: #999999;
}
.job_section .tab_inner_button button.is-active {
  color: #182d65;
}
.job_section .tab_inner .job_section_top {
  margin-bottom: 0;
}
.job_section .tab_inner .job_section_wrap .job_section_bottom {
  display: none;
}
.job_section .tab_inner .job_section_wrap .job_section_bottom.on {
  display: block;
}

.recruit_guide {
  padding-top: 80px;
}
.recruit_guide .inquiry_form {
  padding-top: 20px;
}
.recruit_guide .inquiry_form .category .item {
  width: 100%;
}
.recruit_guide .inquiry_form .category .title {
  padding-left: 33px;
}
.recruit_guide .inquiry_form .category .details {
  width: calc(100% - 165px);
}
.recruit_guide .inquiry_form .item.radio_box {
  padding-right: 18px;
}
.recruit_guide .input-radio label:last-child {
  margin-right: 0;
}
.recruit_guide .form_agree {
  color: #333;
}
.recruit_guide .form_agree .input-checkbox {
  display: flex;
  justify-content: space-between;
}
.recruit_guide .form_agree .agree_title {
  color: #111;
}
.recruit_guide .form_agree .agree_desc .item {
  padding-bottom: 5px;
}
.recruit_guide .form_agree .agree_desc .text_indent {
  text-indent: 10px;
}
.recruit_guide .form_agree .agree_check {
  padding-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recruit_guide .form_agree .agree_check .desc {
  font-size: 16px;
}
.recruit_guide .form_agree .link_term_btn {
  font-size: 14px;
  padding-right: 30px;
  position: relative;
  color: #333;
  display: flex;
  align-items: center;
}
.recruit_guide .form_agree .link_term_btn:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url("../images/link_move_arrow.png") no-repeat center/100% auto;
}
.recruit_guide .button_wrap {
  padding-top: 0;
  padding-bottom: 10px;
}
.recruit_guide .inquiry_detail {
  padding-bottom: 0;
  margin-bottom: 128px;
  position: relative;
}
.recruit_guide .inquiry_detail .table_category {
  padding-bottom: 50px;
}
.recruit_guide .inquiry_detail .table_category .textarea label {
  align-items: flex-start;
  padding: 24px 33px;
}
.recruit_guide .inquiry_detail .table_category .textarea .details {
  padding: 24px 0;
}
.recruit_guide .inquiry_detail .table_category .textarea .item {
  align-items: flex-start;
}
.recruit_guide .inquiry_detail .table_category .item .user_name {
  width: 300px;
}
.recruit_guide .inquiry_detail .table_category .item.detail_contents .details {
  min-height: 200px;
}
.recruit_guide .inquiry_detail .table_category .item.detail_answer {
  border-top: 1px solid #dadada;
}
.recruit_guide .inquiry_detail .table_category .item.detail_answer .details {
  min-height: 200px;
}
.recruit_guide .inquiry_detail .button_wrap {
  padding-bottom: 0;
}
.recruit_guide .inquiry_detail .page_wrap {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.recruit_guide .inquiry_detail .page_wrap .page {
  font-size: 14px;
  color: #999;
  display: flex;
  align-items: center;
}
.recruit_guide .inquiry_detail .page_wrap .page_title {
  max-width: 245px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recruit_guide .inquiry_detail .page_wrap .page .arrow {
  position: relative;
}
.recruit_guide .inquiry_detail .page_wrap .page .arrow:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: url("../images/icon_prev.svg") no-repeat center/100% auto;
  width: 9px;
  height: 15px;
  opacity: 0.5;
}
.recruit_guide .inquiry_detail .page_wrap .page.prev {
  padding-left: 20px;
}
.recruit_guide .inquiry_detail .page_wrap .page.prev .arrow {
  padding-left: 30px;
}
.recruit_guide .inquiry_detail .page_wrap .page.prev .arrow:after {
  left: 0;
}
.recruit_guide .inquiry_detail .page_wrap .page.prev .page_title {
  padding-left: 23px;
}
.recruit_guide .inquiry_detail .page_wrap .page.next {
  padding-right: 20px;
}
.recruit_guide .inquiry_detail .page_wrap .page.next .arrow {
  padding-right: 30px;
}
.recruit_guide .inquiry_detail .page_wrap .page.next .arrow:after {
  right: 0;
  transform: translateY(-50%) rotate(-180deg);
}
.recruit_guide .inquiry_detail .page_wrap .page.next .page_title {
  padding-right: 23px;
}
.recruit_guide .inquiry_detail .page_wrap .page:hover {
  color: #333;
}
.recruit_guide .inquiry_detail .page_wrap .page:hover .arrow:after {
  opacity: 1;
}

.ui-datepicker {
  width: 300px;
  height: 300px;
  margin: 5px auto 0;
  font: 12pt Arial, sans-serif;
}

.ui-datepicker-trigger,
.hasDatepicker {
  cursor: pointer;
}

.ui-datepicker table {
  width: 100%;
}

.ui-datepicker-header {
  background: #fff !important;
  border: none !important;
  color: #ffffff;
  font-family: "Times New Roman";
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #111;
}

.ui-widget-header .ui-datepicker-prev .ui-icon {
  background: url("../images/icon_prev.svg") no-repeat center/100% auto;
}

.ui-widget-header .ui-datepicker-next .ui-icon {
  background: url("../images/icon_next.svg") no-repeat center/100% auto;
}

.ui-widget-header .ui-corner-all .ui-icon {
  background-size: contain;
}
.ui-widget-header .ui-corner-all.ui-state-hover {
  background: none;
  border: none;
  background: #f8f8f8;
  border-radius: 4px;
}

.ui-datepicker .ui-datepicker-title {
  text-align: center;
  font-size: 15px;
}
.ui-datepicker .ui-datepicker-title select {
  height: 30px;
  margin: 0 5px;
  border-radius: 4px;
}

.ui-datepicker-prev {
  float: left;
  cursor: pointer;
  background-position: center -30px;
}

.ui-datepicker-next {
  float: right;
  cursor: pointer;
  background-position: center 0px;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
  border: none;
  background: none;
}

.ui-datepicker th {
  text-transform: uppercase;
  font-size: 8pt;
  color: #666666;
}

.ui-datepicker tbody td {
  padding: 0;
}

.ui-datepicker tbody td:last-child {
  border-right: 0px;
}

.ui-datepicker tbody tr:last-child {
  border-bottom: 0px;
}

.ui-datepicker a {
  text-decoration: none;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: inline-block;
  /*font-weight: bold;*/
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
}

.ui-datepicker-calendar .ui-state-default {
  height: 40px;
  width: 40px;
}

.ui-datepicker-calendar .ui-state-hover {
  background: #33adff;
  color: #FFFFFF;
}

.ui-datepicker-calendar .ui-state-active {
  color: #007aff;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: inset 0px 0px 3px 0px rgba(0, 123, 255, 0.7);
}
.ui-datepicker-calendar .ui-state-active:hover {
  color: #fff;
}

.ui-datepicker-unselectable .ui-state-default {
  color: #999;
}

.search__wrap {
  padding: 73px 0;
  background-color: #f8f8f8;
  border-bottom: 1px solid #dadada;
  margin-bottom: 40px;
}
.search__form {
  display: flex;
  justify-content: center;
  position: relative;
}
.search__form .input-text {
  width: 730px;
  height: 60px;
  border: 1px solid #dadada;
  padding: 0 75px 0 20px;
  position: relative;
}
.search__form .input-text::placeholder {
  color: #999999;
}
.search_btn {
  display: block;
  width: 26px;
  height: 26px;
  background: url(../images/search.png) no-repeat center/100% auto;
  font-size: 0;
  position: absolute;
  right: 26%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
@media (max-width: 1390px) {
  .search_btn {
    right: 22%;
  }
}

.questions__wrap {
  border-top: 1px solid #dadada;
  margin-bottom: 50px;
}
.questions__list .answer__wrap {
  display: none;
}
.questions__list.is-active .answer__wrap {
  display: flex;
  color: #333;
  width: 100%;
  padding: 50px 0;
  border-bottom: 1px solid #dadada;
}
.questions__list.is-active .questions__number {
  background-color: #1a2f66;
  color: #fff;
  font-weight: 700;
}
.questions__list.is-active .questions__title {
  background-color: #1a2f66;
  color: #fff;
  font-weight: 700;
}
.questions__list.is-active .questions__title:after {
  background: url(../images/question_arrow_active.png) no-repeat center/100% auto;
  background-color: #1a2f66;
  color: #fff;
  top: 45%;
}
.questions__title {
  border-bottom: 1px solid #dadada;
  padding: 25px 0;
  font-size: 20px;
  width: 100%;
  text-align: left;
  position: relative;
  display: flex;
}
.questions__title::after {
  content: "";
  width: 16px;
  height: 10px;
  background: url(../images/question_arrow.png) no-repeat center/100% auto;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 43px;
}
.questions__number {
  font-size: 20px;
  color: #333;
  padding: 0 70px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.questions__text {
  width: calc(100% - 270px);
}

.answer__number {
  font-size: 20px;
  padding: 0 75px;
  font-weight: 700;
}

.answer__text {
  font-size: 16px;
  line-height: 1.8;
  width: calc(100% - 170px);
}

.questions_btn {
  display: flex;
  justify-content: center;
  margin-bottom: 138px;
}
.questions_btn .button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 60px;
  width: 200px;
}

.inquiry_wrap {
  border-top: 1px solid #1a2f66;
  border-bottom: 1px solid #1a2f66;
  margin-bottom: 20px;
}
.inquiry_list {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dadada;
}
.inquiry_number {
  font-size: 20px;
  font-weight: 700;
  color: #111;
  width: 167px;
  display: flex;
  justify-content: center;
}
.inquiry_title {
  width: 955px;
}
.inquiry_title a {
  display: block;
  padding: 26px 0;
  font-size: 20px;
  color: #111;
}
.inquiry_date {
  font-size: 16px;
  color: #999;
  width: 172px;
}
.inquiry_waiting {
  color: #999;
}
.inquiry_completion {
  color: #003258;
  padding-left: 10px;
}
.inquiry_no_history {
  margin-top: 24px;
  margin-bottom: 80px;
  background-color: #f8f8f8;
}
.inquiry_no_history .no_history_text {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  padding: 85px 0;
}

.status {
  font-size: 16px;
  font-weight: 700;
  width: 107px;
}

.pagination {
  margin-bottom: 136px;
}

.my_page {
  border-top: 2px solid #182d65;
  padding-top: 75px;
}
.my_page .title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  padding-bottom: 35px;
}
.my_page .desc {
  font-size: 16px;
  color: #333;
  padding-bottom: 30px;
}
.my_page form input {
  width: 300px;
  height: 42px;
  padding: 12px 20px;
  border: 1px solid #dadada;
  font-size: 14px;
}
.my_page form input::placeholder {
  color: #999;
}
.my_page form.form_password {
  padding-bottom: 130px;
}
.my_page .button_wrap {
  padding-top: 40px;
}
.my_page.my_password {
  text-align: center;
}
.my_page.cancel_membership {
  text-align: left;
  padding-bottom: 90px;
}
.my_page.cancel_membership .title,
.my_page.cancel_membership .desc {
  text-align: center;
}
.my_page.cancel_membership .notice {
  width: 645px;
  margin: 0 auto;
  text-align: left;
  color: #999;
  font-size: 14px;
  padding-left: 35px;
}
.my_page.cancel_membership .button_wrap {
  padding-top: 57px;
}
.my_page.edit_info {
  margin: 0 auto;
  width: 926px;
}
.my_page.edit_info .title {
  text-align: center;
}
.my_page.edit_info form {
  color: #333;
  font-size: 14px;
}
.my_page.edit_info form ul {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
.my_page.edit_info form li {
  display: flex;
}
.my_page.edit_info form li.fixed_value {
  display: flex;
  align-items: center;
}
.my_page.edit_info form input {
  width: 504px;
}
.my_page.edit_info form label {
  background: #f8f8f8;
  width: 170px;
  font-size: 16px;
  padding: 6px 25px 15px;
  font-weight: 500;
}
.my_page.edit_info form .fixed_value:first-child p,
.my_page.edit_info form .fixed_value:first-child label {
  padding-top: 30px;
}
.my_page.edit_info form .fixed_value p {
  padding: 4px 36px 28px;
}
.my_page.edit_info form .fixed_value label {
  padding: 4px 25px 28px;
}
.my_page.edit_info form .input_box {
  position: relative;
  padding: 0px 36px 45px;
}
.my_page.edit_info form .red_text {
  display: none;
  position: absolute;
  color: #e92d2d;
  padding: 3px 0 0 20px;
}
.my_page.edit_info form .red_text.is-active {
  display: block;
}
.my_page.edit_info .cancle_link {
  padding-top: 20px;
}
.my_page.edit_info .cancle_link a {
  color: #333;
  font-weight: bold;
}
.my_page.edit_info .button_wrap {
  padding: 42px 0 143px;
}