.application_form {
  padding-bottom: 100px;
  font-size: 14px;

  form {
    .item {
      white-space: nowrap;
    }
  }

  //01. 약관동의
  .step {
    padding: 92px 20px 70px 30px;
    display: flex;
    // justify-content: space-between;

    li {
      text-align: center;
      width: 225px;

      &.current {
        .number {
          background-color: #4c75e2;

          &::after {
            content: '';
            position: absolute;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            width: 223px;
            height: 94px;
            background: url('../images/step_active_dotted.png') no-repeat center/100% auto;
          }

          &::before {
            display: none;
          }
        }

        .title {
          color: #111;
          font-weight: bold;
        }
      }

      &:last-child {

        .number::before {
          display: none;
        }

        &.current {
          .number::after {
            background: url('../images/last_step_active_dotted.png') no-repeat center/100% auto;
            width: 94px;
            height: 94px;
          }
        }
      }
    }

    .number {
      position: relative;
      display: inline-block;
      background-color: $lightGray;
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      border-radius: 50%;

      &:before {
        content: '';
        position: absolute;
        background-color: $lightGray;
        height: 1px;
        width: 113px;
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 20px);
      }
    }

    .title {
      margin-top: 28px;
      color: $lightGray;
      font-size: 18px;
    }

  }

  .button_wrap {
    padding-top: 50px;
  }

  .require_notice {
    font-size: 14px;
    color: #333;
    position: absolute;
    right: 0;
    top: 30px;

    p {
      position: relative;
    }
  }

  .require {
    font-size: 14px;
    color: #e92d2d;
    padding-right: 3px;
    position: absolute;
    left: -8px;
    top: 0;
  }

  .table_category {
    font-size: 14px;
    position: relative;

    .table_title {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 30px;
    }

    select {
      font-size: 14px;

      option {
        color: #111;
      }
    }

    .button.secondary {
      margin-left: 12px;
      width: 140px;
      height: 42px;
      font-size: 14px;
      padding: 0 10px;

      &.add_list {
        margin: 10px 0 0 0;
      }
    }
  }

  .top_border {
    border-top: 2px solid $blue;
  }

  .category {
    display: flex;
    border-bottom: 1px solid $lightGray;
    align-items: center;

    .title {
      flex-shrink: 0;
      font-weight: bold;
      background: #f8f8f8;
      width: 145px;
      font-size: 14px;
      padding-left: 28px;
      min-height: 60px;
      display: flex;
      align-items: center;
      align-self: normal;

      .text {
        position: relative;
      }
    }

    .require {
      font-weight: bold;
    }

    .item {
      padding: 10px 20px;

      label {
        font-size: 14px;
        color: #111;
      }

      .inner_label {
        position: relative;
        margin-right: 16px;
        font-size: 14px;
        color: #111;
      }

      input,
      textarea {

        border: 1px solid $lightGray;
        height: 42px;
        padding: 0 20px;
        font-size: 14px;

        &::placeholder {
          color: #999;
        }

        &:read-only {
          border: none;
          padding: 0 2px;

          &:focus {
            outline: none;
          }
        }

        &.user_name {
          font-weight: bold;
        }
      }

      textarea {
        resize: none;
        overflow-y: auto;
        margin: 10px 0;
        padding: 20px;

        &.detail_spot {
          height: 200px;
        }

        &.detail_spot {
          height: 200px;
        }

        &.detail_project {
          height: 280px;
        }
      }

      .dash {
        margin: 0 10px;
      }

      .unit {
        margin-left: 10px;
        color: #444;
      }

      &.multi {
        display: flex;
        align-items: center;

        select {
          margin-right: 10px;
        }
      }

      &.single_line {
        display: flex;
        align-items: center;
        margin: 10px 0;
      }
    }

    .item_wrap {
      padding-top: 10px;

      .line {
        display: flex;
        align-items: center;
      }

      .item {
        padding-top: 0;
      }
    }


    //셀렉트박스
    select {
      border: 1px solid $lightGray;
      border-right: none;
      height: 42px;
      padding: 0 17px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../images/select_box_arrow.png') no-repeat;
      background-color: #fff;
      background-position: center right;
      color: #999;
      font-size: 16px;

      &:focus {
        color: #111;
      }
    }

    &.no_bottom_line {
      border-bottom: none;
    }

    &.simple {
      .item {
        display: flex;
        align-items: center;
        padding: 0 29px 0 0;

        .title {
          margin-right: 20px;
        }
      }

      .w_full {
        flex: 1;
      }

      .w_50 {
        width: 50%;
      }

      .w_25 {
        width: 25%;
      }
    }

    &.textarea {
      .item {
        padding-right: 0;
      }
    }
  }

  //01. 약관동의
  .form_agree {
    .agree {
      padding-bottom: 50px;

      &:first-of-type {
        margin-top: 60px;
      }

      .input-checkbox {
        padding-bottom: 28px;

        label,
        .agree_title {
          font-weight: bold;
          font-size: 20px;
        }
      }

      &_desc {
        overflow-y: auto;
        border: 1px solid $lightGray;
        padding: 28px;
        font-size: 16px;
        color: #333;

        &.desc_1 {
          height: 284px;
        }

        &.desc_2 {
          height: 252px;
        }
      }
    }

    .message {
      text-align: center;
    }
  }

  //02.기본정보 - 인적사항
  .form_basic {
    .filter {
      background-color: #f8f8f8;
      padding: 30px 30px 10px 30px;
      margin-bottom: 76px;

      &_title {
        width: 80px;
        position: relative;
        font-weight: bold;

        &:after {
          content: '';
          width: 1px;
          height: 12px;
          background-color: #999;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
      }

      .filter_item,
      .desc {
        margin-left: 19px;
      }

      .w_180 {
        width: 13.4%;

        select {
          width: 100%;
        }
      }

      .w_300 {
        width: 22.3%;

        select {
          width: 100%;
        }
      }

      .category {
        border: none;
        padding-bottom: 20px;
      }
    }

    .profile {
      position: relative;
      border: 1px solid #111;
      width: 224px;
      height: 284px;

      .files {
        width: 100%;
        height: 100%;
      }

      &_box {
        width: 100%;
        height: 100%;
        padding: 0 7px 0;
        display: flex;
        align-items: center;

        p {
          line-height: 20px;
          font-size: 12px;
          color: #999;
          text-align: center;
        }
      }

      &_text {
        padding-top: 175px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 17%;
          background: url('../images/profile_img.png') no-repeat;
          width: 146px;
          height: 153px;
        }
      }

      .image_view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          object-fit: contain;
        }

        .del {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 0;
          width: 24px;
          height: 24px;
          background: url('../images/icon_close.svg') no-repeat center/100% auto;
        }
      }
    }

    .info_wrap {
      margin-left: 20px;
      width: calc(100% - 215px);
    }

    .info {
      width: 100%;

      .category {
        width: 100%;

        &.military {
          .item_wrap {
            padding-bottom: 10px;
            padding-left: 10px;
          }

          .inner_label {
            font-weight: bold;
          }

          .military_label {
            padding-right: 34px;
          }
        }

        &.prev_income {
          .inner_label {
            font-weight: bold;
            color: #444;
          }

          .item {
            &:first-of-type {
              padding-right: 7px;
            }

            &:last-of-type {
              padding-left: 7px;
            }
          }
        }
      }

      &.info_1 {
        display: flex;
        align-items: flex-start;
      }

      &.info_2 {
        border-top: 1px solid $lightGray;
      }

      &.info_3 {
        margin-top: 20px;
      }
    }

    .w_284 {
      width: 284px;

      @media (max-width: 1400px) {
        width: 250px;
      }
    }

    .w_300 {
      width: 300px;

      @media (max-width: 1400px) {
        width: 250px;
      }
    }
  }

  //03.학력 경력, 04.어학/자격/기타
  .form_carrer {
    .table_category {
      margin-bottom: 77px;
    }

    .table_category .button.secondary {
      width: 120px;
    }

    .category.major {
      .item {
        padding-right: 60px;
      }
    }

    .category.record {
      .total_score {
        padding-right: 10px;
      }

      .score {
        padding-left: 10px;
      }
    }

    .calender {
      &_wrap {
        display: flex;
        align-items: center;

        .calendar {
          position: relative;
        }

        .tui-datepicker {
          z-index: 99;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 10px;
        }
      }
    }

    select.level {
      margin-left: 10px;
    }

    .w_260 {
      width: 260px;

      @media (max-width: 1400px) {
        width: 220px;
      }
    }

    .w_160 {
      width: 160px;

      @media (max-width: 1400px) {
        width: 120px;
      }
    }

    .w_360 {
      width: 360px;

      @media (max-width: 1400px) {
        width: 300px;
      }
    }

    .w_full {
      width: 1061px;

      @media (max-width: 1400px) {
        width: 960px;
      }
    }
  }

  //05. 자기소개서
  .form_introduce {
    .table_category {
      margin-bottom: 80px;

      >label {
        font-size: 20px;
        font-weight: bold;
      }

      .require {
        padding: 0;
        font-size: 20px;
        position: static;
      }

      &.last {
        margin-bottom: 0;
      }
    }

    textarea {
      margin-top: 30px;
      width: 100%;
      padding: 32px;
      border: 1px solid $lightGray;
      font-size: 16px;
      resize: none;
      height: 286px;
      overflow: auto;

      &::placeholder {
        color: #999;
      }
    }

    .num_characters {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      color: #333;
      padding-top: 12px;
      font-family: $montserrat-font;
    }
  }

  //06. 최종제출
  .form_submit {
    .submit_message {
      background-color: #f8f8f8;
      text-align: center;
      padding: 45px;

      strong {
        font-size: 20px;
        color: #111;
        margin-bottom: 30px;
        display: inline-block;
      }

      p {
        font-size: 16px;
        color: #333;
      }

    }
  }

  //문자열 검색
  .search_box {
    position: relative;

    input {
      padding-right: 40px !important;
    }

    &.is-active {

      .delete_btn,
      .search_list {
        display: block;
      }
    }

    .delete_btn {
      display: none;
      position: absolute;
      font-size: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      background: url('../images/icon_close.svg') no-repeat center/100% auto;
      width: 20px;
      height: 20px;
    }

    .search_list {
      position: absolute;
      display: none;
      background: #fff;
      border-top: $blue;
      width: 100%;
      top: calc(100% + 5px);
      z-index: 9;
      border: 1px solid skyblue;
      max-height: 200px;
      overflow-y: auto;

      li {
        display: block;

        button {
          color: #333;
          padding: 10px 15px;
          font-size: 14px;
        }

        &:hover,
        .on {
          background-color: #f8f8f8;
        }

        &.none {
          color: #333;
          padding: 10px 15px;
          font-size: 14px;
        }
      }
    }
  }

  mark {
    color: #007aff;
    font-style: normal;
    background-color: initial;
  }

}