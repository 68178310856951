.main {
  &__visual {
    background: url('../images/main_bg.jpg') no-repeat;
    background-size:cover;
    height: 900px;
    min-height: 100vh;

    .main-content {
      padding-top: 100px;
      color: #fff;

      .main__inner {
        margin-top: 105px;
        .headline {
          font-size: 60px;
          margin-bottom: 60px;
        }

        .desc {
          font-size: 24px;
        }
      }
    }
  }
  &__job-posting {
    .main__inner{
      padding-top:0;
    }
    .job_notice-list {
      li {
        width:33.33%;
      }
      .title {
        color:#111;
      }
      .item .date{
        padding:0;
        color:#333;
      }
    }
    .button_wrap {
      padding-top: 60px;
    }
  }
  &__notice {
    .notice_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 4px solid #111;
      padding-bottom:20px;
      .section_title {
        font-size: 40px;
        color:#111;
        font-weight: bold;
      }
      .more_btn {
        color:#111;
        position: relative;
        font-weight: bold;
        font-size: 18px;
        padding:10px 44px;
        &:after{
          content: '';
          position: absolute;
          top:50%;
          transform: translateY(-33%);
          right:25px;
          width: 8px;
          height: 11px;
          background: url('../images/more_arrow.png') no-repeat center/100% auto;
        }
      }
    }
    .notice_list{
      padding-bottom: 100px;
      .item{
        border-bottom: 1px solid #eee;
        a{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px; 
        }
        .title{
          width: 80%;
          color:#111;
          @include ellipsis();
        }
        .date{
          width:20%;
          color:#888;
          text-align: right;
        }
        &.emphasis{
          a{
            padding: 40px 25px; 
          }
          .title{
            font-weight: bold;
            font-size: 28px;
          }
        }
        .date{
          
          font-size: 18px;
        }
      }

    }
  }
}