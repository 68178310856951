@charset "utf-8";

.main_button {
	margin-bottom: 100px;
	text-align: center;
	.button {
		display: block;
		color: #fff;
		background-color: #1a2f66;
		height: 60px;
		width: 200px;
		margin: 0 auto;
	}
}

.join_content {
	display: flex;
	width: 100%;
	.title {
		width: 190px;
		font-size: 20px;
		font-weight: bold;
	}
	.red_text {
		color: #e92d2d;
		font-size: 14px;
	}
	&_01 {
		margin-bottom: 80px;
		.form {
			&__inner {
				padding-top: 20px;
				padding-bottom: 10px;
				position: relative;
				width: 100%;
				border-top: 2px solid #1a2f66;
				border-bottom: 1px solid #dadada;
				margin-top: 5px;
				&:before {
					content: '';
					width: 145px;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					background-color: #f8f8f8;
				}
				.item {
					display: flex;
					align-items: flex-start;
					padding-bottom: 10px;
					.red_text{
						margin-bottom: 10px;
					}
				}
				.double_check{
					.button {
						&.secondary {
							width: 140px;
							margin-left: 10px;
							height: 40px;
							font-size: 14px;
						}
						&.trans {
							width: 140px;
							margin-left: 10px;
							height: 40px;
							font-size: 14px;
							background-color: #999;
							color: #fff;
						}
					}
				}
				.label {
					width: 145px;
					font-size: 16px;
					color: #333;
					padding: 0 26px;
					// background-color: #f8f8f8;
					position: relative;
					display: flex;
					align-items: center;
					height: 40px;
					font-weight: 500;
				}
				.red_text {
					margin-left: 57px;
					padding-top: 10px;
					display: none;
					transition: all .1s ease-out;
					&.is-active{
						display: block;
					}
				}
				.input-text {
					width: 600px;
					margin-left: 40px;
					border: 1px solid #999;
					padding: 0 20px;
					height: 40px;
					color: #111;
					font-size: 14px;
					&::placeholder {
						color: #999;
						font-size: 14px;
					}
				}
			}
		}
	}
	&_02 {
		margin-bottom: 80px;
		.checkbox__inner {
			width: 100%;
			display: flex;
			border-top: 2px solid #1a2f66;
			border-bottom: 1px solid #dadada;
			margin-top: 5px;
			.allcheck {
				width: 145px;
				padding: 20px 0 0 26px;
				background-color: #f8f8f8;
				font-size: 16px;
				color: #333;
				input[id=allAgree]{
					display: none;
				}
				input[id=allAgree]:checked + label::before{
					background: url(../images/checked.png)no-repeat center/100% auto;
				}
				label{
					position: relative;
					padding-left: 30px;
					font-weight: 500;
					&::before{
						content: '';
						width: 20px;
						height: 20px;
						position: absolute;
						top: 4px;
						left: 0;
						background: url(../images/checkbox.png)no-repeat center/100% auto;
					}
				}

			}
			.checkbox {
				display: flex;
				flex-direction: column;
				margin-left: 40px;
				padding-top: 22px;
				width: 390px;
				.check_inner {
					padding-bottom: 22px;
					position: relative;
					.red_text {
						font-size: 16px;
						margin: 0 5px;
					}
				}
				input{
					display: none;
				}
				input:checked + label::before{
					background: url(../images/checked.png)no-repeat center/100% auto;
				}
				label{
					position: relative;
					padding-left: 30px;
					font-size: 16px;
					&::before{
						content: '';
						width: 20px;
						height: 20px;
						position: absolute;
						top: 4px;
						left: 0;
						background: url(../images/checkbox.png)no-repeat center/100% auto;
					}
				}
				.Terms {
					font-size: 14px;
					color: #999;
					border-bottom: 1px solid #999;
					padding-right: 20px;
					position: absolute;
					top: 5px;
					right: 0;
					cursor: pointer;
					&::after{
						content: '';
						width: 11px;
						height: 8px;
						position: absolute;
						top: 7px;
						right: 0px;
						background: url(../images/terms_arrow.png)no-repeat center/100% auto;
					}
				}
			}
		}
	}
}

.success{
	&_content{
		text-align: center;
		padding-bottom: 80px;
	}
	&_title{
		font-size: 20px;
		font-weight: bold;
		color: #333;
		padding-bottom: 33px;
	}
	&_text{
		font-size: 16px;
		color: #333;
	}
	&_button{
		text-align: center;
		margin: 0 auto;
		padding-bottom: 100px;
		a{
			display: inline-block;
			width: 200px;
			height: 60px;
			margin-right: 20px;
			font-size: 16px;
			padding-top: 15px;
		}
		.secondary{
			color: #000;
		}
	}
}