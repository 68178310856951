.sub-tab_link{
  font-size: 20px;
  display: flex;
  align-items: center;
  li{
    display: flex;
    align-items: center;
    position: relative;
    height: 32px;
    & + li{
      &:before{
        content: "";
        width: 1px;
        height: 12px;
        display: block;
        background-color: #dadada;
        margin: 0 20px;
      }
    }
    &.is-active{
      a {
        color: $blue;
        position: relative;
        font-weight: bold;
        z-index: 1;
  
        &::after {
          content: "";
          width: 100%;
          height: 10px;
          position: absolute;
          left: -5px;
          bottom: 0px;
          z-index: -1;
          background-color: #d3d8e7;
          display: block;
          padding: 0 5px;
        }
      }
    }
  }
  a{
    color: #999;
  }
}

.job_notice{
  padding-top: 70px;


  .info{
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .type{
    color: #1a2f66;
    &.always{
      color: #a0775b;
    }
    & + .company{
      display: flex;
      align-items: center;
      &:before{
        content: "";
        width: 1px;
        height: 16px;
        margin: 0 8px;
        background-color: #1a2f66;
      }
    }
  }
  .company{
    color: #999;
  }
  .title,
  .date,
  .d-day{
    font-family: $mix-font;
  }
  .title{
    display: block;
    font-size: 24px;
  }
  .date{
    color: #999;
  }
}

.job_notice-list{
  margin-top: 25px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
  li{
    width: 50%;
    padding-right: 20px;
    padding-bottom: 20px;
    &:hover{
      .item{
        padding: 6px;
        background: rgb(40,82,194);
        background: linear-gradient(160deg, rgba(40,82,194,1) 0%, rgba(24,45,101,1) 70%, rgba(24,45,101,1) 100%);
        border-color: transparent;
        transition: background-color .3s ease;
        &_box{
          //padding: 36px 38px;
        }
      }
    }
  }
  .item{
    padding: 6px;
    //background-color: #dadada;
    height: 250px;
    border: 1px solid #dadada;
    &_box{
      background-color: #fff;
      width: 100%;
      height: 100%;
      padding: 24px 26px;
      position: relative;
      font-size: 18px;
    }
    .info{
      padding-right: 68px;
    }
    .title{
      height: 78px;
      margin-bottom: 27px;
      @include ellipsis(2);
      white-space: normal;
    }
    .d-day{
      position: absolute;
      right: 30px;
      top: 30px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      color: $blue;
    }
    .date{
      padding-right: 150px;
    }
    .button{
      position: absolute;
      right: 35px;
      bottom: 22px;
      width: 140px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
    }
    .cancel{
      pointer-events: none;
    }
  }
}

.job_notice-detail{
  margin-top: 25px;
  border-top: 1px solid #dadada;
  .detail_info{
    padding: 25px 20px;
    font-size: 18px;
    border-bottom: 1px solid #dadada;
    .title{
      font-size: 24px;
      padding-bottom: 24px;
    }
    .d-day{
      font-size: 20px;
      padding: 4px 12px;
      background-color: $blue;
      color: #fff;
      font-weight: bold;
      margin-left: 16px;
    }
  }
  .detail_contents{
    padding: 25px 20px;
    border-bottom: 1px solid #dadada;
    color: #111;
    font-size: 16px;
    p{
      margin-bottom: 25px;
    }
  }
  .detail_group{
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    .button{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 60px;
      font-size: 16px;
    }
    .secondary{
      color: #000;
    }
  }
}