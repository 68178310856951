@charset "utf-8";

.search {
	&__wrap {
		padding: 73px 0;
		background-color: #f8f8f8;
		border-bottom: 1px solid #dadada;
		margin-bottom: 40px;
	}
	&__form {
		display: flex;
		justify-content: center;
		position: relative;
		.input-text {
			width: 730px;
			height: 60px;
			border: 1px solid #dadada;
			padding: 0 75px 0 20px;
			position: relative;
			&::placeholder {
				color: #999999;
			}
		}
	}
	&_btn {
		display: block;
		width: 26px;
		height: 26px;
		background: url(../images/search.png) no-repeat center/100% auto;
		font-size: 0;
		position: absolute;
		right: 26%;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		@media (max-width: 1390px) {
			right: 22%;
		}
	}
}
.questions {
	&__wrap {
		border-top: 1px solid #dadada;
		margin-bottom: 50px;
	}
	&__list {
		.answer__wrap {
			display: none;
		}
		&.is-active {
			.answer__wrap {
				display: flex;
				color: #333;
				width: 100%;
				padding: 50px 0;
				border-bottom: 1px solid #dadada;
			}
			.questions__number {
				background-color: #1a2f66;
				color: #fff;
				font-weight: 700;
			}
			.questions__title {
				background-color: #1a2f66;
				color: #fff;
				font-weight: 700;
				&:after {
					background: url(../images/question_arrow_active.png) no-repeat center/100% auto;
					background-color: #1a2f66;
					color: #fff;
					top: 45%;
				}
			}
		}
	}
	&__title {
		border-bottom: 1px solid #dadada;
		padding: 25px 0;
		font-size: 20px;
		width: 100%;
		text-align: left;
		position: relative;
		display: flex;
		&::after {
			content: '';
			width: 16px;
			height: 10px;
			background: url(../images/question_arrow.png) no-repeat center/100% auto;
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			right: 43px;
		}
	}
	&__number {
		font-size: 20px;
		color: #333;
		padding: 0 70px;
		font-weight: 700;
		display: flex;
		align-items: center;
	}
	&__text {
		width: calc(100% - 270px);
	}
}
.answer__number {
	font-size: 20px;
	padding: 0 75px;
	font-weight: 700;
}
.answer__text {
	font-size: 16px;
	line-height: 1.8;
	width: calc(100% - 170px);
}

.questions_btn {
	display: flex;
	justify-content: center;
	margin-bottom: 138px;
	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		height: 60px;
		width: 200px;
	}
}

//채용문의 문의내역확인
.inquiry {
	&_wrap {
		border-top: 1px solid #1a2f66;
		border-bottom: 1px solid #1a2f66;
		margin-bottom: 20px;
	}
	&_list {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #dadada;
	}
	&_number {
		font-size: 20px;
		font-weight: 700;
		color: #111;
		width: 167px;
		display: flex;
		justify-content: center;
	}
	&_title {
		width: 955px;
		a {
			display: block;
			padding: 26px 0;
			font-size: 20px;
			color: #111;
		}
	}
	&_date {
		font-size: 16px;
		color: #999;
		width: 172px;
	}
	&_waiting {
		color: #999;
	}
	&_completion {
		color: #003258;
		padding-left: 10px;
	}
	&_no_history {
		margin-top: 24px;
		margin-bottom: 80px;
		background-color: #f8f8f8;
		.no_history_text {
			text-align: center;
			font-weight: 700;
			font-size: 20px;
			padding: 85px 0;
		}
	}
}
.status {
	font-size: 16px;
	font-weight: 700;
	width: 107px;
}
.pagination {
	margin-bottom: 136px;
}
