@charset "utf-8";

.blind {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0
}

body {
  min-width: 1280px;

  &.layer-opens {
    overflow: hidden;
  }

  &.nav-opens {
    overflow: hidden;
  }

  &.search-opens {}
}

#wrapper {
  width: 100%;
  position: relative;
  min-height: 100vh;
}

// 공통
.main {

  // 전체 여백 설정
  &__inner {
    max-width: 1480px;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 auto;
  }
}

//상단 title, desc
.top {
  padding-bottom: 70px;

  .main_title {
    font-size: 34px;
    font-weight: 700;
    color: $blue;
  }

  .main_desc {
    font-size: 50px;
    color: #333;
  }
}

#container {
  width: 100%;
  min-height: 600px;
}

//버튼 공통 색깔 
.button {
  &.secondary {
    color: #000;
    border: 1px solid #000;
    background-color: #fff;
  }

  &.primary {
    color: #fff;
    background-color: #1a2f66;
    border: 1px solid #1a2f66;
  }

  &.cancel {
    color: #fff;
    background-color: #999;
  }

  &.calendar_btn {
    background: url('../images/icon_calender.png') no-repeat center/100% auto;
    width: 28px;
    height: 28px;
    margin-left: 10px;
  }
}

.button_wrap {
  display: flex;
  justify-content: center;

  .button {
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    +.button {
      margin-left: 20px;
    }
  }
}

//breadcrumb
.breadcrumb {
  padding-top: 55px;
  padding-bottom: 65px;
  font-family: $mix-font;
  display: flex;
  align-items: center;

  a,
  span {
    font-size: 16px;
    color: #000;
  }

  .gt {
    position: relative;
    display: block;
    width: 7px;
    height: 12px;
    background: url(../images/breadarow.png) no-repeat center/100% auto;
  }

  .home {
    margin-right: 15px;
    font-weight: 500;
  }

  .route {
    margin: 0 18px;
  }

  .current {
    margin-left: 18px;
  }
}

// form 요소 / input, label, select, checkbox, radio
.input {
  &-text {}

  &-label {}

  &-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

  }

  &-checkbox {
    position: relative;

    input {
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      left: -999px;
      top: 0;
    }

    label {
      position: relative;
      padding-left: 35px;

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('../images/checkbox_blank.png');
      }
    }

    input:checked+label:before {
      background-image: url('../images/checkbox_checked.png');
    }

  }

  &-radio {
    position: relative;

    input {
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      left: -999px;
      top: 0;
    }

    label {
      color: #444 !important;
      position: relative;
      padding-left: 32px;
      margin-right: 35px;

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 20px;
        height: 20px;
        background-image: url('../images/radiobox_blank.png');
      }
    }

    input:checked+label:before {
      background-image: url('../images/radiobox_checked.png');
    }
  }
}


.main__inner {
  // max-width: 1400px;
  margin: 0 auto;
  padding-top: 100px;

}

header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  // width: 100%;
  z-index: $GNB;
  background-color: $blue;
  border-bottom: 1px solid rgba(255, 255, 255, 20%);

  &.main_header {
    background-color: transparent;

    &.scroll {
      background-color: #fff;

      .logos a {
        background: url('../images/logo_blue.png') no-repeat center/100% auto;
      }

      .login_btn {
        color: #000;
      }

      .depth1 {
        .title {
          color: $black;
        }
      }

      .right .user_name {
        color: #000;
      }
    }
  }

  &::after {
    display: none;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    top: 100px;
    background-color: #e5e5e5;
  }

  .gnb {
    width: 100%;
    max-width: 1600px;
    padding: 0 20px;
    //padding: 0 160px;
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    position: relative;

    .logos {
      margin-top: 18px;
      width: 160px;
      height: 66px;
      flex-shrink: 0;

      a {
        background: url('../images/logo.png') no-repeat center/100% auto;
        display: block;
        height: 100%;
        padding: 20px 0;
      }
    }

    nav {
      display: flex;
      flex: 1;
      padding-left: 160px;
    }

    .login_btn {
      padding: 7px 27px;
      font-size: 16px;
      border: 1px solid #999;
      color: #fff;
      font-weight: bold;
      margin-left: 45px;
    }

    @media (max-width: 1640px) {

      //padding: 0 40px;
      .login_btn {
        right: 20px;
      }

      nav {
        padding-left: 80px;
        padding-right: 50px;
      }
    }
  }

  .depth1 {
    display: flex;

    &_item {
      &:nth-child(3) .depth2 {
        margin-right: 0;
      }

      @media (max-width: 1439px) {
        width: 185px;
      }
    }

    .title {
      color: $white;
      display: inline-block;
      font-size: 20px;
      position: relative;
      line-height: 99px;
      margin: 0px 75px;

      &.on {
        &::after {

          position: absolute;
          bottom: -1px;
          left: 0px;
          width: 100%;
          height: 4px;
          background: #fff;
          content: "";
        }
      }
    }

  }

  .depth2 {
    display: none;
    padding: 30px 0 45px;
    margin: 0px 60px;
    white-space: nowrap;

    &_item {
      padding: 15px 17px;

      a {
        color: #6f6f6f;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          width: 0%;
          height: 2px;
          margin-top: 5px;
          top: 100%;
          left: 0%;
          background-color: $blue;
          transition: width 0.3s;
        }

        &:hover {
          color: $blue;

          &:after {
            width: 100%;
          }
        }
      }

      &.is-active a {
        color: $blue;

        &:after {

          position: absolute;
          content: '';
          width: 0%;
          height: 2px;
          margin-top: 5px;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: $blue;
        }
      }
    }
  }

  .right {
    margin-top: 30px;
    color: #fff;
    display: flex;
    align-items: center;

    .user_name {
      font-size: 16px;
      color: #fff;
      position: relative;

      &:after {
        content: '|';
        font-size: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 20px;
      }
    }
  }

  &:hover {
    background-color: #fff;
    border-bottom: none;

    .logos a {
      background: url('../images/logo_blue.png') no-repeat center/100% auto;
    }

    .login_btn {
      color: #000;
    }

    .depth1 {
      .title {
        color: $black;

        &.on {
          &:after {
            background: $blue;
          }
        }
      }
    }

    .depth2 {
      display: block;
    }

    &::after {
      display: block;
    }

    .right {
      color: #000;

      .user_name {
        color: #000;
      }
    }
  }
}

footer {
  width: 100%;
  background-color: #182d65;

  .main__inner {
    padding-top: 45px;
  }

  .footer_top {
    padding-bottom: 80px;
    margin-bottom: 40px;
    border-bottom: 1px solid #0f1c3f;
  }

  .footer_list_inner {
    display: flex;
  }

  .footer_list {
    width: 216px;
    margin-right: 178px;

    &:nth-of-type(4) {
      margin-right: 0;
    }

    .list_item {
      padding-bottom: 20px;
      border-bottom: 1px solid #8596c2;
      font-size: 24px;
      font-weight: bold;
      padding-left: 10px;

      a {
        color: #fff;
      }
    }
  }

  .list2 {
    padding-top: 20px;
  }

  .list2_item {
    padding-bottom: 12px;
    padding-left: 10px;

    &:nth-last-of-type(1) {
      padding-bottom: 0;
    }

    a {
      font-weight: 100;
      font-size: 18px;
      color: #8596c2;
    }
  }

  .footer_bottom {
    padding-bottom: 66px;
  }

  .bottom1_list {
    display: flex;
    padding-bottom: 30px;
  }

  .bottom1_list_item {
    margin-right: 12px;
    padding-right: 12px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 12px;
      background-color: #8596c2;
      top: 10px;
      right: 0;
    }

    &:nth-last-of-type(1) {
      &::before {
        display: none;
      }
    }

    a {
      font-size: 16px;
      color: #8596c2;
    }
  }

  .white {
    a {
      color: #fff;
      font-weight: 400;
    }
  }

  .bottom2 {
    padding-bottom: 30px;
  }

  .address {
    display: flex;
  }

  .address_list {
    font-size: 14px;
    color: #8596c2;
    margin-right: 10px;
    padding-right: 10px;

    &.num {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 12px;
        background-color: #8596c2;
        top: 6px;
        right: 0;
      }
    }
  }

  .copy {
    font-size: 14px;
    color: #8596c2;
  }

  //select
  .select_box {
    position: relative;
    font-size: 16px;
    width: 200px;
    flex: 0 0 auto;
    z-index: 2;

    .current {
      padding-left: 18px;
      height: 50px;
      position: relative;
      color: #fff;
      background-color: #182d65;
      border: 1px solid #8596c2;
      border-radius: 0;
      width: 100%;
      //height: 100%;
      text-align: left;
      font-weight: 500;
      z-index: 5;
      display: inline-flex;
      align-items: center;

      &:after {
        content: "";
        width: 12px;
        height: 8px;
        right: 22px;
        position: absolute;
        top: 50%;
        background: url('../images/select_arrow.png') no-repeat center/100% auto;
        transform: translateY(-50%);
        transition: transform .2s ease;
      }
    }

    .option-dropdown {
      position: absolute;
      left: 0;
      //top: calc(100% - 420px);
      bottom: 49px;
      background-color: #182d65;
      border-radius: 0;
      width: 0;
      overflow: hidden;
      transform: translateY(0px);
      transition: transform .3s ease;
    }

    &.is-active {
      .current {
        border: 1px solid #8596c2;
        border-radius: 0;

        &:after {
          transform: translateY(-50%) scaleY(-1);
        }
      }

      .option-dropdown {
        width: 100%;
        border: 1px solid #8596c2;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.1);
        }

        overflow-y: auto;
        transform: translateY(0);
        padding: 10px 0;

        a {
          color: #fff;
          padding: 10px 10px 10px 18px;
          //padding-right: 0;
        }
      }
    }
  }
}

//이메일 무단수집거부
.footer_email_wrap {
  margin-bottom: 120px;
}

.footer_email_text {
  font-size: 16px;
  padding-bottom: 30px;
  color: #333;
  line-height: 2;
}

//이용약관 개인정보
.footer_wrap {
  margin-bottom: 180px;
}

.footer_text_inner {
  padding-bottom: 40px;
}

.footer_text {
  font-size: 16px;
  color: #333;
  text-indent: -20px;
  padding-left: 20px;
  line-height: 32px;
}

.p_text {
  text-indent: 0;
  padding-left: 0;
  padding-bottom: 40px;
}

.footer_terms_privacy_title {
  display: block;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
}

.button_top {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 160px;
  bottom: 40px;
  background: url(../images/top_button.png) no-repeat;
  display: none;
  z-index: 999;

  &.show {
    display: block;
  }
}

.tab_link {
  display: flex;
  padding-left: 1px;
  border-bottom: 2px solid $blue;

  li {
    border: 1px solid $lightGray;
    border-bottom: none;
    width: 170px;
    height: 54px;
    margin-left: -1px;

    &.is-active {
      border: 1px solid transparent;
      background-color: $blue;

      a {
        font-weight: bold;
        color: #fff;
      }
    }
  }

  a {
    display: block;
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 20px;
    line-height: 54px;
  }
}

.pagination {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 30px;

  li {
    display: inline-block;
    vertical-align: top;

    a {
      width: 41px;
      height: 41px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #111;
      border: 1px solid #dadada;
      padding-top: 1px;
      font-family: $montserrat-font;
    }

    &.is-current a {
      color: $white;
      background-color: $blue;
    }

    &.page {
      position: relative;
      margin: 0 8px;

      a {
        font-size: 0;

        &:after {
          content: "";
          background: no-repeat left center/auto 90%;
          height: 16px;
        }
      }

      &.prev a,
      &.next a {
        &:after {
          width: 8px;
          height: 14px;
          background-image: url(../images/page_next.png);
        }
      }

      &.prev a {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
  }
}